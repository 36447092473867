module.exports = [
  {
    date: '2022-10-29',
    open: 36.01,
    close: 35.1,
    high: 36,
    low: 34.98,
    volume: 2617774,
    uOpen: 36.25,
    uClose: 33.9,
    uHigh: 34.98,
    uLow: 34.64,
    uVolume: 2653970,
    change: 0,
    changePercent: 0,
    label: 'Oct 29, 19',
    changeOverTime: 0
  },
  {
    date: '2022-10-30',
    open: 34.8,
    close: 34.33,
    high: 35.3,
    low: 34.23,
    volume: 1779815,
    uOpen: 34.5,
    uClose: 34.5,
    uHigh: 34.69,
    uLow: 34.61,
    uVolume: 1795109,
    change: -0.63,
    changePercent: -1.8933,
    label: 'Oct 30, 19',
    changeOverTime: -0.018531
  },
  {
    date: '2022-10-31',
    open: 33.53,
    close: 33.17,
    high: 34.62,
    low: 33.23,
    volume: 2803319,
    uOpen: 33.96,
    uClose: 32.9,
    uHigh: 33.17,
    uLow: 32.39,
    uVolume: 2781295,
    change: -0.64,
    changePercent: -1.8875,
    label: 'Oct 31, 19',
    changeOverTime: -0.036709
  },
  {
    date: '2022-11-01',
    open: 33.21,
    close: 34.55,
    high: 34.4,
    low: 33.66,
    volume: 2141225,
    uOpen: 34.12,
    uClose: 34.35,
    uHigh: 34.7,
    uLow: 33.89,
    uVolume: 2168664,
    change: 1.12,
    changePercent: 3.4975,
    label: 'Nov 1, 19',
    changeOverTime: -0.003292
  },
  {
    date: '2022-11-04',
    open: 34.9,
    close: 35.59,
    high: 35.5,
    low: 34.98,
    volume: 1519871,
    uOpen: 34.2,
    uClose: 35.06,
    uHigh: 34.8,
    uLow: 34.59,
    uVolume: 1486370,
    change: 0.29,
    changePercent: 0.8865,
    label: 'Nov 4, 19',
    changeOverTime: 0.005584
  },
  {
    date: '2022-11-05',
    open: 35.77,
    close: 33.38,
    high: 35.76,
    low: 33.8,
    volume: 1926635,
    uOpen: 34.46,
    uClose: 34.28,
    uHigh: 34.42,
    uLow: 33.97,
    uVolume: 1912218,
    change: -0.65,
    changePercent: -1.8931,
    label: 'Nov 5, 19',
    changeOverTime: -0.013049
  },
  {
    date: '2022-11-06',
    open: 33.88,
    close: 34.61,
    high: 34.13,
    low: 34.07,
    volume: 2111260,
    uOpen: 34.41,
    uClose: 33.72,
    uHigh: 34.09,
    uLow: 34.23,
    uVolume: 2085817,
    change: 0.28,
    changePercent: 0.8178,
    label: 'Nov 6, 19',
    changeOverTime: -0.00518
  },
  {
    date: '2022-11-07',
    open: 34.2,
    close: 34.26,
    high: 36.22,
    low: 34.36,
    volume: 6259928,
    uOpen: 33.8,
    uClose: 33.59,
    uHigh: 36.23,
    uLow: 34.34,
    uVolume: 6398894,
    change: -0.19,
    changePercent: -0.592,
    label: 'Nov 7, 19',
    changeOverTime: -0.0107
  },
  {
    date: '2022-11-08',
    open: 38.38,
    close: 37.7,
    high: 40.1,
    low: 36.66,
    volume: 10755989,
    uOpen: 37.85,
    uClose: 38.33,
    uHigh: 39.4,
    uLow: 36.58,
    uVolume: 10519839,
    change: 4.3,
    changePercent: 12.3091,
    label: 'Nov 8, 19',
    changeOverTime: 0.115294
  },
  {
    date: '2022-11-11',
    open: 38.14,
    close: 39.99,
    high: 40.41,
    low: 37.79,
    volume: 4011919,
    uOpen: 37.43,
    uClose: 39.7,
    uHigh: 40.45,
    uLow: 38.07,
    uVolume: 3998775,
    change: 1.09,
    changePercent: 2.9954,
    label: 'Nov 11, 19',
    changeOverTime: 0.145084
  },
  {
    date: '2022-11-12',
    open: 39.98,
    close: 39.59,
    high: 39.56,
    low: 38.82,
    volume: 3794301,
    uOpen: 40.03,
    uClose: 39.62,
    uHigh: 39.35,
    uLow: 38.77,
    uVolume: 3735517,
    change: -0.8,
    changePercent: -2.1297,
    label: 'Nov 12, 19',
    changeOverTime: 0.123626
  },
  {
    date: '2022-11-13',
    open: 38.97,
    close: 39.69,
    high: 39.98,
    low: 38.35,
    volume: 2324031,
    uOpen: 37.65,
    uClose: 38.26,
    uHigh: 40,
    uLow: 37.51,
    uVolume: 2298571,
    change: 0.33,
    changePercent: 0.8629,
    label: 'Nov 13, 19',
    changeOverTime: 0.131566
  },
  {
    date: '2022-11-14',
    open: 38.86,
    close: 39.85,
    high: 40.82,
    low: 38.56,
    volume: 2593341,
    uOpen: 39.42,
    uClose: 39.21,
    uHigh: 39.71,
    uLow: 39.04,
    uVolume: 2584979,
    change: 0.85,
    changePercent: 2.2146,
    label: 'Nov 14, 19',
    changeOverTime: 0.153453
  },
  {
    date: '2022-11-15',
    open: 41,
    close: 40.52,
    high: 39.81,
    low: 40.48,
    volume: 2314080,
    uOpen: 41,
    uClose: 41.52,
    uHigh: 40.31,
    uLow: 38.69,
    uVolume: 2306652,
    change: 0.76,
    changePercent: 1.8872,
    label: 'Nov 15, 19',
    changeOverTime: 0.179917
  },
  {
    date: '2022-11-18',
    open: 40.46,
    close: 40.6,
    high: 41.4,
    low: 39.95,
    volume: 1877989,
    uOpen: 39.63,
    uClose: 41.6,
    uHigh: 41,
    uLow: 40.04,
    uVolume: 1854103,
    change: 0.28,
    changePercent: 0.6903,
    label: 'Nov 18, 19',
    changeOverTime: 0.189738
  },
  {
    date: '2022-11-19',
    open: 40.02,
    close: 38.83,
    high: 40.22,
    low: 37.94,
    volume: 2486004,
    uOpen: 39.7,
    uClose: 39,
    uHigh: 39.95,
    uLow: 38.19,
    uVolume: 2455938,
    change: -2.17,
    changePercent: -5.4722,
    label: 'Nov 19, 19',
    changeOverTime: 0.121886
  },
  {
    date: '2022-11-20',
    open: 38.53,
    close: 40.35,
    high: 39.78,
    low: 38.11,
    volume: 2824626,
    uOpen: 38.08,
    uClose: 40,
    uHigh: 39.68,
    uLow: 38.39,
    uVolume: 2753415,
    change: 1.11,
    changePercent: 3.011,
    label: 'Nov 20, 19',
    changeOverTime: 0.154067
  },
  {
    date: '2022-11-21',
    open: 39.6,
    close: 40.09,
    high: 41.33,
    low: 38.87,
    volume: 3427350,
    uOpen: 40.88,
    uClose: 41.7,
    uHigh: 40.13,
    uLow: 40.44,
    uVolume: 3368350,
    change: 0.93,
    changePercent: 2.45,
    label: 'Nov 21, 19',
    changeOverTime: 0.181371
  },
  {
    date: '2022-11-22',
    open: 40.51,
    close: 43.2,
    high: 41.41,
    low: 40.2,
    volume: 3748525,
    uOpen: 39.99,
    uClose: 42.6,
    uHigh: 41.69,
    uLow: 41.6,
    uVolume: 3744138,
    change: 1.36,
    changePercent: 3.3768,
    label: 'Nov 22, 19',
    changeOverTime: 0.224899
  },
  {
    date: '2022-11-25',
    open: 42.43,
    close: 42.63,
    high: 41.9,
    low: 41.7,
    volume: 2797902,
    uOpen: 42.21,
    uClose: 42.61,
    uHigh: 41.76,
    uLow: 42.2,
    uVolume: 2787678,
    change: -0.21,
    changePercent: -0.5315,
    label: 'Nov 25, 19',
    changeOverTime: 0.211682
  },
  {
    date: '2022-11-26',
    open: 41.87,
    close: 40.39,
    high: 42.62,
    low: 40.42,
    volume: 2368962,
    uOpen: 42.26,
    uClose: 41.7,
    uHigh: 41.67,
    uLow: 40.45,
    uVolume: 2344501,
    change: -0.7,
    changePercent: -1.7388,
    label: 'Nov 26, 19',
    changeOverTime: 0.191759
  },
  {
    date: '2022-11-27',
    open: 41.92,
    close: 40,
    high: 41.98,
    low: 39.97,
    volume: 2331335,
    uOpen: 41.88,
    uClose: 41,
    uHigh: 40.72,
    uLow: 39.54,
    uVolume: 2320826,
    change: -1.2,
    changePercent: -3.114,
    label: 'Nov 27, 19',
    changeOverTime: 0.154846
  },
  {
    date: '2022-11-29',
    open: 39.96,
    close: 39.75,
    high: 39.65,
    low: 39.95,
    volume: 940129,
    uOpen: 39.77,
    uClose: 40.44,
    uHigh: 39.97,
    uLow: 38.9,
    uVolume: 936524,
    change: 0.16,
    changePercent: 0.3998,
    label: 'Nov 29, 19',
    changeOverTime: 0.1583
  },
  {
    date: '2022-12-02',
    open: 39.69,
    close: 41.17,
    high: 41.82,
    low: 38.75,
    volume: 3215782,
    uOpen: 40.3,
    uClose: 41.32,
    uHigh: 40.6,
    uLow: 39.75,
    uVolume: 3114082,
    change: 0.72,
    changePercent: 1.8667,
    label: 'Dec 2, 19',
    changeOverTime: 0.1814
  },
  {
    date: '2022-12-03',
    open: 40.98,
    close: 40.46,
    high: 42.09,
    low: 40.85,
    volume: 2415909,
    uOpen: 39.51,
    uClose: 42.17,
    uHigh: 41.35,
    uLow: 40.85,
    uVolume: 2358707,
    change: 0.34,
    changePercent: 0.824,
    label: 'Dec 3, 19',
    changeOverTime: 0.1935
  },
  {
    date: '2022-12-04',
    open: 41.67,
    close: 41.84,
    high: 42.78,
    low: 41,
    volume: 2687715,
    uOpen: 41.34,
    uClose: 40.91,
    uHigh: 42.21,
    uLow: 42,
    uVolume: 2645240,
    change: 0.41,
    changePercent: 0.9874,
    label: 'Dec 4, 19',
    changeOverTime: 0.201521
  },
  {
    date: '2022-12-05',
    open: 41.71,
    close: 41.56,
    high: 42.91,
    low: 41.3,
    volume: 1613906,
    uOpen: 41.6,
    uClose: 43.05,
    uHigh: 41.24,
    uLow: 41.6,
    uVolume: 1632653,
    change: 0.5,
    changePercent: 1.1908,
    label: 'Dec 5, 19',
    changeOverTime: 0.220602
  },
  {
    date: '2022-12-06',
    open: 43.2,
    close: 42.78,
    high: 43.5,
    low: 42.6,
    volume: 4979568,
    uOpen: 41.41,
    uClose: 42.55,
    uHigh: 43.52,
    uLow: 41.73,
    uVolume: 4874735,
    change: 0.02,
    changePercent: 0.0493,
    label: 'Dec 6, 19',
    changeOverTime: 0.223518
  },
  {
    date: '2022-12-09',
    open: 41.54,
    close: 41.24,
    high: 43.26,
    low: 41.04,
    volume: 4044464,
    uOpen: 41.53,
    uClose: 42.49,
    uHigh: 42.82,
    uLow: 40.61,
    uVolume: 4064602,
    change: -0.3,
    changePercent: -0.7155,
    label: 'Dec 9, 19',
    changeOverTime: 0.215761
  },
  {
    date: '2022-12-10',
    open: 42.46,
    close: 43.16,
    high: 42.6,
    low: 41.65,
    volume: 4041974,
    uOpen: 42.63,
    uClose: 43.45,
    uHigh: 42,
    uLow: 40.61,
    uVolume: 3978412,
    change: 0.77,
    changePercent: 1.8707,
    label: 'Dec 10, 19',
    changeOverTime: 0.233554
  },
  {
    date: '2022-12-11',
    open: 42.9,
    close: 44.07,
    high: 44.34,
    low: 42.59,
    volume: 3608823,
    uOpen: 43.2,
    uClose: 44.06,
    uHigh: 44.95,
    uLow: 42.14,
    uVolume: 3538718,
    change: 1.26,
    changePercent: 3.0731,
    label: 'Dec 11, 19',
    changeOverTime: 0.276079
  },
  {
    date: '2022-12-12',
    open: 43.6,
    close: 43.88,
    high: 44.1,
    low: 43.03,
    volume: 2553774,
    uOpen: 44.1,
    uClose: 44.67,
    uHigh: 43.4,
    uLow: 43.77,
    uVolume: 2565016,
    change: -0.2,
    changePercent: -0.4835,
    label: 'Dec 12, 19',
    changeOverTime: 0.25902
  },
  {
    date: '2022-12-13',
    open: 43.5,
    close: 43.33,
    high: 44.35,
    low: 41.94,
    volume: 3337673,
    uOpen: 42.6,
    uClose: 43.62,
    uHigh: 43.98,
    uLow: 42.11,
    uVolume: 3225719,
    change: -0.28,
    changePercent: -0.6349,
    label: 'Dec 13, 19',
    changeOverTime: 0.257432
  },
  {
    date: '2022-12-16',
    open: 44.34,
    close: 44,
    high: 44.67,
    low: 43.35,
    volume: 3170427,
    uOpen: 44.32,
    uClose: 44,
    uHigh: 44.21,
    uLow: 42.53,
    uVolume: 3254426,
    change: 0.75,
    changePercent: 1.793,
    label: 'Dec 16, 19',
    changeOverTime: 0.278754
  },
  {
    date: '2022-12-17',
    open: 43.78,
    close: 44.75,
    high: 45.61,
    low: 43.6,
    volume: 1885914,
    uOpen: 43.74,
    uClose: 45.31,
    uHigh: 44.04,
    uLow: 44.3,
    uVolume: 1843376,
    change: 0.29,
    changePercent: 0.7075,
    label: 'Dec 17, 19',
    changeOverTime: 0.286196
  },
  {
    date: '2022-12-18',
    open: 44.7,
    close: 46.06,
    high: 45.44,
    low: 44.35,
    volume: 5388376,
    uOpen: 44,
    uClose: 45.56,
    uHigh: 46.55,
    uLow: 45.25,
    uVolume: 5397277,
    change: 0.97,
    changePercent: 2.2507,
    label: 'Dec 18, 19',
    changeOverTime: 0.324178
  },
  {
    date: '2022-12-19',
    open: 45.88,
    close: 46.42,
    high: 46.25,
    low: 44.41,
    volume: 1799269,
    uOpen: 44.75,
    uClose: 45.4,
    uHigh: 45.49,
    uLow: 44.44,
    uVolume: 1814408,
    change: 0.7,
    changePercent: 1.5879,
    label: 'Dec 19, 19',
    changeOverTime: 0.341763
  },
  {
    date: '2022-12-20',
    open: 45.68,
    close: 44,
    high: 47.08,
    low: 43.82,
    volume: 2130269,
    uOpen: 46.59,
    uClose: 44.32,
    uHigh: 47.38,
    uLow: 45.7,
    uVolume: 2116397,
    change: -1.02,
    changePercent: -2.3298,
    label: 'Dec 20, 19',
    changeOverTime: 0.313338
  },
  {
    date: '2022-12-23',
    open: 45.06,
    close: 46,
    high: 45.57,
    low: 45.11,
    volume: 1745837,
    uOpen: 45.93,
    uClose: 45.7,
    uHigh: 45.33,
    uLow: 44.13,
    uVolume: 1773684,
    change: 0.49,
    changePercent: 1.1098,
    label: 'Dec 23, 19',
    changeOverTime: 0.318653
  },
  {
    date: '2022-12-24',
    open: 45.95,
    close: 45.49,
    high: 45.4,
    low: 46.3,
    volume: 2281142,
    uOpen: 46.01,
    uClose: 45.62,
    uHigh: 45.18,
    uLow: 46,
    uVolume: 2272076,
    change: 0.6,
    changePercent: 1.3653,
    label: 'Dec 24, 19',
    changeOverTime: 0.340028
  },
  {
    date: '2022-12-26',
    open: 45.23,
    close: 45.81,
    high: 47.52,
    low: 45.69,
    volume: 1007288,
    uOpen: 46.82,
    uClose: 47.07,
    uHigh: 46.55,
    uLow: 46.71,
    uVolume: 1004690,
    change: 0.6,
    changePercent: 1.2985,
    label: 'Dec 26, 19',
    changeOverTime: 0.352027
  },
  {
    date: '2022-12-27',
    open: 47.63,
    close: 46.7,
    high: 47.13,
    low: 45.41,
    volume: 1547642,
    uOpen: 46.5,
    uClose: 47.8,
    uHigh: 47.99,
    uLow: 47.29,
    uVolume: 1496844,
    change: 0.54,
    changePercent: 1.2306,
    label: 'Dec 27, 19',
    changeOverTime: 0.37105
  },
  {
    date: '2022-12-30',
    open: 46.74,
    close: 47.75,
    high: 47.17,
    low: 45.94,
    volume: 1564049,
    uOpen: 46.76,
    uClose: 46.76,
    uHigh: 46.96,
    uLow: 46.03,
    uVolume: 1570778,
    change: -0.48,
    changePercent: -1.0364,
    label: 'Dec 30, 19',
    changeOverTime: 0.35
  },
  {
    date: '2022-12-31',
    open: 47.13,
    close: 47.24,
    high: 47.68,
    low: 45.63,
    volume: 1546059,
    uOpen: 47.43,
    uClose: 46.78,
    uHigh: 47.51,
    uLow: 46.22,
    uVolume: 1579825,
    change: 0.31,
    changePercent: 0.7029,
    label: 'Dec 31, 19',
    changeOverTime: 0.366055
  },
  {
    date: '2023-01-02',
    open: 47.08,
    close: 45.43,
    high: 46.63,
    low: 46.91,
    volume: 2244769,
    uOpen: 46.64,
    uClose: 46.29,
    uHigh: 48.74,
    uLow: 45.32,
    uVolume: 2209444,
    change: -0.76,
    changePercent: -1.634,
    label: 'Jan 2, 20',
    changeOverTime: 0.337854
  },
  {
    date: '2023-01-03',
    open: 44.83,
    close: 46.74,
    high: 47.4,
    low: 44.77,
    volume: 2231937,
    uOpen: 46.56,
    uClose: 44.97,
    uHigh: 47.52,
    uLow: 44.61,
    uVolume: 2231156,
    change: -0.51,
    changePercent: -1.1018,
    label: 'Jan 3, 20',
    changeOverTime: 0.327509
  },
  {
    date: '2023-01-06',
    open: 45.13,
    close: 46.2,
    high: 46.06,
    low: 46.13,
    volume: 2636978,
    uOpen: 45.18,
    uClose: 44.93,
    uHigh: 46.7,
    uLow: 44.17,
    uVolume: 2539124,
    change: -0.08,
    changePercent: -0.1806,
    label: 'Jan 6, 20',
    changeOverTime: 0.32392
  },
  {
    date: '2023-01-07',
    open: 46.95,
    close: 47.21,
    high: 46.58,
    low: 46.09,
    volume: 2204053,
    uOpen: 45.83,
    uClose: 46.18,
    uHigh: 47.73,
    uLow: 45.64,
    uVolume: 2184855,
    change: 1.15,
    changePercent: 2.5379,
    label: 'Jan 7, 20',
    changeOverTime: 0.368658
  },
  {
    date: '2023-01-08',
    open: 46.18,
    close: 46.75,
    high: 47.4,
    low: 46.74,
    volume: 2835645,
    uOpen: 46.19,
    uClose: 47.15,
    uHigh: 46.5,
    uLow: 47.48,
    uVolume: 2840960,
    change: 0,
    changePercent: 0,
    label: 'Jan 8, 20',
    changeOverTime: 0.367444
  },
  {
    date: '2023-01-09',
    open: 47.65,
    close: 46.1,
    high: 46.69,
    low: 45.94,
    volume: 1798940,
    uOpen: 48.41,
    uClose: 47.18,
    uHigh: 48.4,
    uLow: 46.41,
    uVolume: 1871398,
    change: 0.01,
    changePercent: 0.0222,
    label: 'Jan 9, 20',
    changeOverTime: 0.364816
  },
  {
    date: '2023-01-10',
    open: 47.7,
    close: 47.41,
    high: 46.19,
    low: 47.06,
    volume: 1438084,
    uOpen: 48.12,
    uClose: 46.72,
    uHigh: 46.48,
    uLow: 46.51,
    uVolume: 1475593,
    change: -0.2,
    changePercent: -0.428,
    label: 'Jan 10, 20',
    changeOverTime: 0.350805
  },
  {
    date: '2023-01-13',
    open: 47.05,
    close: 47.19,
    high: 48.96,
    low: 47.1,
    volume: 1885520,
    uOpen: 46.72,
    uClose: 46.58,
    uHigh: 48.74,
    uLow: 46.4,
    uVolume: 1835214,
    change: 1.03,
    changePercent: 2.1742,
    label: 'Jan 13, 20',
    changeOverTime: 0.391872
  },
  {
    date: '2023-01-14',
    open: 46.35,
    close: 48.11,
    high: 48.66,
    low: 46.73,
    volume: 2852495,
    uOpen: 48.22,
    uClose: 48.85,
    uHigh: 49.41,
    uLow: 48.11,
    uVolume: 2926009,
    change: 0.49,
    changePercent: 1.092,
    label: 'Jan 14, 20',
    changeOverTime: 0.41189
  },
  {
    date: '2023-01-15',
    open: 47.81,
    close: 48.09,
    high: 48.18,
    low: 47.99,
    volume: 2449875,
    uOpen: 47.84,
    uClose: 47.93,
    uHigh: 49.16,
    uLow: 47.4,
    uVolume: 2508068,
    change: -0.4,
    changePercent: -0.8613,
    label: 'Jan 15, 20',
    changeOverTime: 0.39127
  },
  {
    date: '2023-01-16',
    open: 48.04,
    close: 47.66,
    high: 48.84,
    low: 47,
    volume: 4557541,
    uOpen: 49.19,
    uClose: 48.97,
    uHigh: 48.19,
    uLow: 47,
    uVolume: 4554773,
    change: 0.5,
    changePercent: 1.0982,
    label: 'Jan 16, 20',
    changeOverTime: 0.398581
  },
  {
    date: '2023-01-17',
    open: 47.66,
    close: 48.89,
    high: 49.02,
    low: 48.71,
    volume: 3070011,
    uOpen: 49.23,
    uClose: 49.04,
    uHigh: 48.24,
    uLow: 47.69,
    uVolume: 3009781,
    change: 0.79,
    changePercent: 1.672,
    label: 'Jan 17, 20',
    changeOverTime: 0.434328
  },
  {
    date: '2023-01-21',
    open: 48.18,
    close: 49.52,
    high: 50.67,
    low: 48.11,
    volume: 1693203,
    uOpen: 49.58,
    uClose: 50.43,
    uHigh: 50.62,
    uLow: 49.17,
    uVolume: 1689118,
    change: 0.19,
    changePercent: 0.4057,
    label: 'Jan 21, 20',
    changeOverTime: 0.431955
  },
  {
    date: '2023-01-22',
    open: 48.3,
    close: 48.28,
    high: 50.6,
    low: 49.41,
    volume: 1566984,
    uOpen: 48.8,
    uClose: 48.63,
    uHigh: 49.05,
    uLow: 48.95,
    uVolume: 1573676,
    change: -0.06,
    changePercent: -0.1265,
    label: 'Jan 22, 20',
    changeOverTime: 0.424542
  },
  {
    date: '2023-01-23',
    open: 48.48,
    close: 48.47,
    high: 50.19,
    low: 49.69,
    volume: 1550914,
    uOpen: 48.01,
    uClose: 49.02,
    uHigh: 49.62,
    uLow: 48.69,
    uVolume: 1523544,
    change: 0.14,
    changePercent: 0.298,
    label: 'Jan 23, 20',
    changeOverTime: 0.443
  },
  {
    date: '2023-01-24',
    open: 50.1,
    close: 50.6,
    high: 49.11,
    low: 48.47,
    volume: 3162138,
    uOpen: 49.7,
    uClose: 49.12,
    uHigh: 49.82,
    uLow: 48.12,
    uVolume: 3182422,
    change: 0.22,
    changePercent: 0.4506,
    label: 'Jan 24, 20',
    changeOverTime: 0.445355
  },
  {
    date: '2023-01-27',
    open: 49.64,
    close: 48.94,
    high: 49.37,
    low: 47.8,
    volume: 2098460,
    uOpen: 47.95,
    uClose: 48.07,
    uHigh: 48.95,
    uLow: 47.4,
    uVolume: 2043522,
    change: -1.27,
    changePercent: -2.6018,
    label: 'Jan 27, 20',
    changeOverTime: 0.400044
  },
  {
    date: '2023-01-28',
    open: 47.8,
    close: 47.78,
    high: 48.51,
    low: 49.02,
    volume: 2086436,
    uOpen: 48.5,
    uClose: 48.31,
    uHigh: 49.16,
    uLow: 48.43,
    uVolume: 2088876,
    change: -0.02,
    changePercent: -0.0426,
    label: 'Jan 28, 20',
    changeOverTime: 0.396792
  },
  {
    date: '2023-01-29',
    open: 48.5,
    close: 49.25,
    high: 50.08,
    low: 48.19,
    volume: 2075493,
    uOpen: 48.04,
    uClose: 48.71,
    uHigh: 49.79,
    uLow: 47.88,
    uVolume: 2075113,
    change: 0.58,
    changePercent: 1.2293,
    label: 'Jan 29, 20',
    changeOverTime: 0.41235
  },
  {
    date: '2023-01-30',
    open: 48.46,
    close: 48.57,
    high: 49.47,
    low: 47.89,
    volume: 1293161,
    uOpen: 47.81,
    uClose: 49.07,
    uHigh: 48.86,
    uLow: 46.91,
    uVolume: 1316043,
    change: -0.4,
    changePercent: -0.8497,
    label: 'Jan 30, 20',
    changeOverTime: 0.417095
  },
  {
    date: '2023-01-31',
    open: 49.2,
    close: 46.78,
    high: 49,
    low: 45.52,
    volume: 1937638,
    uOpen: 48.8,
    uClose: 46.63,
    uHigh: 47.6,
    uLow: 45.87,
    uVolume: 1892186,
    change: -1.14,
    changePercent: -2.4,
    label: 'Jan 31, 20',
    changeOverTime: 0.37076
  },
  {
    date: '2023-02-03',
    open: 47.84,
    close: 48.85,
    high: 47.17,
    low: 46.75,
    volume: 1058785,
    uOpen: 46.7,
    uClose: 48.06,
    uHigh: 47.33,
    uLow: 46.87,
    uVolume: 1062627,
    change: 0.38,
    changePercent: 0.8027,
    label: 'Feb 3, 20',
    changeOverTime: 0.396564
  },
  {
    date: '2023-02-04',
    open: 49.22,
    close: 49.4,
    high: 49.65,
    low: 47.97,
    volume: 1668735,
    uOpen: 49.39,
    uClose: 50.83,
    uHigh: 49.9,
    uLow: 48.42,
    uVolume: 1667918,
    change: 2.5,
    changePercent: 5.2626,
    label: 'Feb 4, 20',
    changeOverTime: 0.462467
  },
  {
    date: '2023-02-05',
    open: 51.35,
    close: 50.71,
    high: 51,
    low: 49.71,
    volume: 2257712,
    uOpen: 51.23,
    uClose: 49.19,
    uHigh: 50.2,
    uLow: 49.44,
    uVolume: 2255050,
    change: 0.14,
    changePercent: 0.2661,
    label: 'Feb 5, 20',
    changeOverTime: 0.464711
  },
  {
    date: '2023-02-06',
    open: 49.32,
    close: 51.11,
    high: 51.13,
    low: 50.42,
    volume: 3494975,
    uOpen: 50.67,
    uClose: 51.6,
    uHigh: 51.75,
    uLow: 50.58,
    uVolume: 3493465,
    change: 1.05,
    changePercent: 2.2215,
    label: 'Feb 6, 20',
    changeOverTime: 0.503036
  },
  {
    date: '2023-02-07',
    open: 52.43,
    close: 52.01,
    high: 53.58,
    low: 51.07,
    volume: 2812294,
    uOpen: 52.61,
    uClose: 51.6,
    uHigh: 51.57,
    uLow: 51.99,
    uVolume: 2883659,
    change: 0.02,
    changePercent: 0.0412,
    label: 'Feb 7, 20',
    changeOverTime: 0.50744
  },
  {
    date: '2023-02-10',
    open: 52,
    close: 52.25,
    high: 52.82,
    low: 51.48,
    volume: 2088125,
    uOpen: 52,
    uClose: 51.91,
    uHigh: 51.64,
    uLow: 52.4,
    uVolume: 2112854,
    change: 0.29,
    changePercent: 0.5644,
    label: 'Feb 10, 20',
    changeOverTime: 0.504868
  },
  {
    date: '2023-02-11',
    open: 50.75,
    close: 52.38,
    high: 53.93,
    low: 51.59,
    volume: 2656238,
    uOpen: 52.79,
    uClose: 52.52,
    uHigh: 52.18,
    uLow: 52.66,
    uVolume: 2664417,
    change: 0.38,
    changePercent: 0.7623,
    label: 'Feb 11, 20',
    changeOverTime: 0.513742
  },
  {
    date: '2023-02-12',
    open: 52.19,
    close: 52.76,
    high: 52.01,
    low: 51.52,
    volume: 1761837,
    uOpen: 53.13,
    uClose: 51.41,
    uHigh: 53.8,
    uLow: 51.42,
    uVolume: 1774812,
    change: -0.16,
    changePercent: -0.3163,
    label: 'Feb 12, 20',
    changeOverTime: 0.511551
  },
  {
    date: '2023-02-13',
    open: 51.55,
    close: 53.73,
    high: 55.21,
    low: 52.06,
    volume: 3881933,
    uOpen: 52.94,
    uClose: 51.84,
    uHigh: 53.5,
    uLow: 50.46,
    uVolume: 3729984,
    change: 1.08,
    changePercent: 2.1462,
    label: 'Feb 13, 20',
    changeOverTime: 0.553317
  },
  {
    date: '2023-02-14',
    open: 52,
    close: 52.98,
    high: 52.58,
    low: 51.32,
    volume: 1372336,
    uOpen: 51,
    uClose: 51.96,
    uHigh: 53.72,
    uLow: 52.06,
    uVolume: 1348117,
    change: 0.14,
    changePercent: 0.2799,
    label: 'Feb 14, 20',
    changeOverTime: 0.544297
  },
  {
    date: '2023-02-18',
    open: 53.39,
    close: 52.36,
    high: 55.66,
    low: 54.1,
    volume: 4904739,
    uOpen: 52.51,
    uClose: 53.62,
    uHigh: 54.01,
    uLow: 51.9,
    uVolume: 4857484,
    change: 0.44,
    changePercent: 0.87,
    label: 'Feb 18, 20',
    changeOverTime: 0.550921
  },
  {
    date: '2023-02-19',
    open: 55.1,
    close: 56.42,
    high: 57.5,
    low: 52.84,
    volume: 7249561,
    uOpen: 55.03,
    uClose: 54.69,
    uHigh: 57.75,
    uLow: 53.19,
    uVolume: 6974177,
    change: 2.3,
    changePercent: 4.352,
    label: 'Feb 19, 20',
    changeOverTime: 0.611965
  },
  {
    date: '2023-02-20',
    open: 63.93,
    close: 63.7,
    high: 67.88,
    low: 64.67,
    volume: 20152454,
    uOpen: 62.91,
    uClose: 64.05,
    uHigh: 67.32,
    uLow: 62.9,
    uVolume: 19494864,
    change: 9.28,
    changePercent: 17.0981,
    label: 'Feb 20, 20',
    changeOverTime: 0.921422
  },
  {
    date: '2023-02-21',
    open: 63.23,
    close: 65,
    high: 66.57,
    low: 65.64,
    volume: 7042314,
    uOpen: 65.16,
    uClose: 66,
    uHigh: 68,
    uLow: 65.32,
    uVolume: 6955470,
    change: 1.38,
    changePercent: 2.2297,
    label: 'Feb 21, 20',
    changeOverTime: 0.953469
  },
  {
    date: '2023-02-24',
    open: 63.1,
    close: 60.1,
    high: 63.07,
    low: 58.86,
    volume: 8915859,
    uOpen: 61.86,
    uClose: 62.47,
    uHigh: 63.91,
    uLow: 61.1,
    uVolume: 8638833,
    change: -5.38,
    changePercent: -8.2212,
    label: 'Feb 24, 20',
    changeOverTime: 0.782824
  },
  {
    date: '2023-02-25',
    open: 60.3,
    close: 59.29,
    high: 62,
    low: 58.46,
    volume: 3362544,
    uOpen: 62.86,
    uClose: 58.9,
    uHigh: 61.3,
    uLow: 58.58,
    uVolume: 3362907,
    change: -1.95,
    changePercent: -3.3124,
    label: 'Feb 25, 20',
    changeOverTime: 0.726049
  },
  {
    date: '2023-02-26',
    open: 58.4,
    close: 57.97,
    high: 60.67,
    low: 58.87,
    volume: 4794291,
    uOpen: 59.5,
    uClose: 59.69,
    uHigh: 59.69,
    uLow: 56.82,
    uVolume: 4822847,
    change: -0.7,
    changePercent: -1.2435,
    label: 'Feb 26, 20',
    changeOverTime: 0.698812
  },
  {
    date: '2023-02-27',
    open: 57,
    close: 55.85,
    high: 59.07,
    low: 54.35,
    volume: 4047778,
    uOpen: 56,
    uClose: 54.64,
    uHigh: 59.27,
    uLow: 54.07,
    uVolume: 3927905,
    change: -2.52,
    changePercent: -4.4424,
    label: 'Feb 27, 20',
    changeOverTime: 0.620978
  },
  {
    date: '2023-02-28',
    open: 56,
    close: 56.97,
    high: 57.3,
    low: 52.03,
    volume: 5851971,
    uOpen: 55,
    uClose: 58.45,
    uHigh: 57.3,
    uLow: 52.81,
    uVolume: 5921181,
    change: 1.24,
    changePercent: 2.2791,
    label: 'Feb 28, 20',
    changeOverTime: 0.660746
  },
  {
    date: '2023-03-02',
    open: 56.44,
    close: 54.5,
    high: 58.17,
    low: 54.44,
    volume: 4166814,
    uOpen: 58.29,
    uClose: 55,
    uHigh: 58.2,
    uLow: 54.18,
    uVolume: 4179194,
    change: -1.48,
    changePercent: -2.5378,
    label: 'Mar 2, 20',
    changeOverTime: 0.610416
  },
  {
    date: '2023-03-03',
    open: 56.36,
    close: 55.55,
    high: 56.81,
    low: 51.9,
    volume: 3165374,
    uOpen: 54.99,
    uClose: 54.5,
    uHigh: 56.8,
    uLow: 53.5,
    uVolume: 3053736,
    change: -1.23,
    changePercent: -2.1907,
    label: 'Mar 3, 20',
    changeOverTime: 0.58889
  },
  {
    date: '2023-03-04',
    open: 55.15,
    close: 54.52,
    high: 57.01,
    low: 55.65,
    volume: 1969099,
    uOpen: 56.48,
    uClose: 54.58,
    uHigh: 56.72,
    uLow: 54.34,
    uVolume: 1970551,
    change: 1.09,
    changePercent: 2.0472,
    label: 'Mar 4, 20',
    changeOverTime: 0.61544
  },
  {
    date: '2023-03-05',
    open: 55.25,
    close: 52.89,
    high: 54.42,
    low: 51.08,
    volume: 2420775,
    uOpen: 55.01,
    uClose: 53.83,
    uHigh: 55.61,
    uLow: 53.23,
    uVolume: 2377498,
    change: -2.8,
    changePercent: -5.204,
    label: 'Mar 5, 20',
    changeOverTime: 0.525582
  },
  {
    date: '2023-03-06',
    open: 51.01,
    close: 51.77,
    high: 53.36,
    low: 48.87,
    volume: 3141870,
    uOpen: 50.81,
    uClose: 51.49,
    uHigh: 51.29,
    uLow: 49.78,
    uVolume: 3182581,
    change: -2.2,
    changePercent: -4.4353,
    label: 'Mar 6, 20',
    changeOverTime: 0.481616
  },
  {
    date: '2023-03-09',
    open: 46.75,
    close: 45.17,
    high: 46.38,
    low: 44.49,
    volume: 4652912,
    uOpen: 46.53,
    uClose: 45.76,
    uHigh: 46.93,
    uLow: 46.04,
    uVolume: 4672987,
    change: -4.57,
    changePercent: -9.2491,
    label: 'Mar 9, 20',
    changeOverTime: 0.342603
  },
  {
    date: '2023-03-10',
    open: 47.08,
    close: 46.71,
    high: 48.43,
    low: 42.11,
    volume: 4682252,
    uOpen: 48.29,
    uClose: 46.74,
    uHigh: 48.87,
    uLow: 41.65,
    uVolume: 4639639,
    change: 0.43,
    changePercent: 0.941,
    label: 'Mar 10, 20',
    changeOverTime: 0.344948
  },
  {
    date: '2023-03-11',
    open: 45.38,
    close: 43.03,
    high: 44.84,
    low: 40.25,
    volume: 5323041,
    uOpen: 43.82,
    uClose: 42.28,
    uHigh: 45.35,
    uLow: 41.45,
    uVolume: 5332339,
    change: -4,
    changePercent: -8.617,
    label: 'Mar 11, 20',
    changeOverTime: 0.232207
  },
  {
    date: '2023-03-12',
    open: 38,
    close: 36.63,
    high: 38.68,
    low: 34.75,
    volume: 7912132,
    uOpen: 40,
    uClose: 37.05,
    uHigh: 38.44,
    uLow: 34.97,
    uVolume: 7791062,
    change: -6.17,
    changePercent: -14.4803,
    label: 'Mar 12, 20',
    changeOverTime: 0.052644
  },
  {
    date: '2023-03-13',
    open: 39.47,
    close: 37.51,
    high: 41,
    low: 34.56,
    volume: 4708163,
    uOpen: 39.03,
    uClose: 37.94,
    uHigh: 41,
    uLow: 34.56,
    uVolume: 4791822,
    change: 0.84,
    changePercent: 2.3551,
    label: 'Mar 13, 20',
    changeOverTime: 0.076718
  },
  {
    date: '2023-03-16',
    open: 32.53,
    close: 29.97,
    high: 33,
    low: 28.01,
    volume: 7337898,
    uOpen: 31.52,
    uClose: 28.9,
    uHigh: 33,
    uLow: 28.23,
    uVolume: 7466123,
    change: -7.71,
    changePercent: -21.2668,
    label: 'Mar 16, 20',
    changeOverTime: -0.154443
  },
  {
    date: '2023-03-17',
    open: 29.3,
    close: 29.3,
    high: 30.92,
    low: 25.89,
    volume: 5700201,
    uOpen: 30.6,
    uClose: 28.29,
    uHigh: 31.17,
    uLow: 25.59,
    uVolume: 5531575,
    change: -0.57,
    changePercent: -1.9431,
    label: 'Mar 17, 20',
    changeOverTime: -0.172099
  },
  {
    date: '2023-03-18',
    open: 27.17,
    close: 25.21,
    high: 26.81,
    low: 20.08,
    volume: 9483232,
    uOpen: 26.74,
    uClose: 25.61,
    uHigh: 27,
    uLow: 20.23,
    uVolume: 9740632,
    change: -3.15,
    changePercent: -11.3624,
    label: 'Mar 18, 20',
    changeOverTime: -0.266802
  },
  {
    date: '2023-03-19',
    open: 25.22,
    close: 27.34,
    high: 28.27,
    low: 23.48,
    volume: 5296850,
    uOpen: 25.32,
    uClose: 26.38,
    uHigh: 27.79,
    uLow: 23.63,
    uVolume: 5478589,
    change: 1.35,
    changePercent: 5.256,
    label: 'Mar 19, 20',
    changeOverTime: -0.230395
  },
  {
    date: '2023-03-20',
    open: 27.62,
    close: 27.88,
    high: 32.21,
    low: 26.66,
    volume: 8228380,
    uOpen: 27.61,
    uClose: 28.15,
    uHigh: 32.24,
    uLow: 27.4,
    uVolume: 8251335,
    change: 0.91,
    changePercent: 3.4186,
    label: 'Mar 20, 20',
    changeOverTime: -0.205202
  },
  {
    date: '2023-03-23',
    open: 28.04,
    close: 32.35,
    high: 32.22,
    low: 26.9,
    volume: 7862800,
    uOpen: 27.61,
    uClose: 32.33,
    uHigh: 31.69,
    uLow: 27.06,
    uVolume: 7896729,
    change: 4.16,
    changePercent: 14.8559,
    label: 'Mar 23, 20',
    changeOverTime: -0.07647
  },
  {
    date: '2023-03-24',
    open: 35.85,
    close: 38.47,
    high: 40.1,
    low: 35.3,
    volume: 8720822,
    uOpen: 36.76,
    uClose: 36.83,
    uHigh: 40.4,
    uLow: 35.2,
    uVolume: 8558076,
    change: 5.9,
    changePercent: 18.8177,
    label: 'Mar 24, 20',
    changeOverTime: 0.090634
  },
  {
    date: '2023-03-25',
    open: 37.17,
    close: 39.98,
    high: 44.24,
    low: 37.87,
    volume: 6776258,
    uOpen: 37.44,
    uClose: 40.08,
    uHigh: 45.34,
    uLow: 37.54,
    uVolume: 6863668,
    change: 3.09,
    changePercent: 8.5549,
    label: 'Mar 25, 20',
    changeOverTime: 0.183329
  },
  {
    date: '2023-03-26',
    open: 41.5,
    close: 42.71,
    high: 42.71,
    low: 39.8,
    volume: 5865166,
    uOpen: 41.2,
    uClose: 42.18,
    uHigh: 43.72,
    uLow: 40.64,
    uVolume: 5875536,
    change: 0.92,
    changePercent: 2.297,
    label: 'Mar 26, 20',
    changeOverTime: 0.20815
  },
  {
    date: '2023-03-27',
    open: 38.58,
    close: 39.06,
    high: 40.81,
    low: 38.45,
    volume: 4504876,
    uOpen: 39.43,
    uClose: 38.77,
    uHigh: 41.12,
    uLow: 37.12,
    uVolume: 4504415,
    change: -2.91,
    changePercent: -7.2413,
    label: 'Mar 27, 20',
    changeOverTime: 0.12377
  },
  {
    date: '2023-03-30',
    open: 39.75,
    close: 37.43,
    high: 40.28,
    low: 36.18,
    volume: 4598458,
    uOpen: 39.06,
    uClose: 38.56,
    uHigh: 41.4,
    uLow: 36.11,
    uVolume: 4477356,
    change: -0.9,
    changePercent: -2.3815,
    label: 'Mar 30, 20',
    changeOverTime: 0.09786
  },
  {
    date: '2023-03-31',
    open: 37.86,
    close: 37.03,
    high: 39.19,
    low: 35.89,
    volume: 4176393,
    uOpen: 37.57,
    uClose: 36.92,
    uHigh: 38.4,
    uLow: 35.24,
    uVolume: 4162168,
    change: -1.08,
    changePercent: -2.8227,
    label: 'Mar 31, 20',
    changeOverTime: 0.06873
  },
  {
    date: '2023-04-01',
    open: 35.09,
    close: 33,
    high: 35.94,
    low: 32.4,
    volume: 6054881,
    uOpen: 34.36,
    uClose: 32.45,
    uHigh: 35.34,
    uLow: 31.97,
    uVolume: 6090273,
    change: -4.59,
    changePercent: -12.8553,
    label: 'Apr 1, 20',
    changeOverTime: -0.071469
  },
  {
    date: '2023-04-02',
    open: 32.51,
    close: 31.36,
    high: 33.19,
    low: 29.83,
    volume: 4340447,
    uOpen: 31.21,
    uClose: 31.38,
    uHigh: 33.4,
    uLow: 30.48,
    uVolume: 4284035,
    change: -1.32,
    changePercent: -4.3042,
    label: 'Apr 2, 20',
    changeOverTime: -0.1138
  },
  {
    date: '2023-04-03',
    open: 31.58,
    close: 31.02,
    high: 31.32,
    low: 29.53,
    volume: 3371618,
    uOpen: 30.98,
    uClose: 31.16,
    uHigh: 31.72,
    uLow: 30.05,
    uVolume: 3331987,
    change: 0.18,
    changePercent: 0.6249,
    label: 'Apr 3, 20',
    changeOverTime: -0.103632
  },
  {
    date: '2023-04-06',
    open: 32.8,
    close: 38.43,
    high: 38.93,
    low: 32.66,
    volume: 4929697,
    uOpen: 33.01,
    uClose: 38.13,
    uHigh: 38.26,
    uLow: 33.49,
    uVolume: 5050497,
    change: 7.06,
    changePercent: 22.8363,
    label: 'Apr 6, 20',
    changeOverTime: 0.098608
  },
  {
    date: '2023-04-07',
    open: 40.22,
    close: 39.6,
    high: 42.79,
    low: 38,
    volume: 5956689,
    uOpen: 40.27,
    uClose: 39.1,
    uHigh: 43.14,
    uLow: 39.1,
    uVolume: 5933744,
    change: 0.71,
    changePercent: 1.8806,
    label: 'Apr 7, 20',
    changeOverTime: 0.122962
  },
  {
    date: '2023-04-08',
    open: 40.82,
    close: 40.3,
    high: 41.3,
    low: 39.59,
    volume: 5700339,
    uOpen: 39.16,
    uClose: 40.42,
    uHigh: 41.8,
    uLow: 39.04,
    uVolume: 5551999,
    change: 1.41,
    changePercent: 3.7573,
    label: 'Apr 8, 20',
    changeOverTime: 0.166948
  },
  {
    date: '2023-04-09',
    open: 42.56,
    close: 42.12,
    high: 42.83,
    low: 40.26,
    volume: 4748921,
    uOpen: 42.56,
    uClose: 41.15,
    uHigh: 43.9,
    uLow: 40.82,
    uVolume: 4829968,
    change: 1.46,
    changePercent: 3.8487,
    label: 'Apr 9, 20',
    changeOverTime: 0.20994
  },
  {
    date: '2023-04-13',
    open: 41.14,
    close: 39.57,
    high: 42.09,
    low: 37.97,
    volume: 2536659,
    uOpen: 40.38,
    uClose: 39.97,
    uHigh: 41.64,
    uLow: 38.96,
    uVolume: 2626143,
    change: -2.19,
    changePercent: -5.5932,
    label: 'Apr 13, 20',
    changeOverTime: 0.139859
  },
  {
    date: '2023-04-14',
    open: 40.33,
    close: 39.5,
    high: 42.01,
    low: 39.7,
    volume: 3572563,
    uOpen: 41.12,
    uClose: 39.7,
    uHigh: 41.02,
    uLow: 38.6,
    uVolume: 3479573,
    change: 0.26,
    changePercent: 0.6722,
    label: 'Apr 14, 20',
    changeOverTime: 0.149793
  },
  {
    date: '2023-04-15',
    open: 38.24,
    close: 37.46,
    high: 37.72,
    low: 37.13,
    volume: 3626593,
    uOpen: 38.6,
    uClose: 36.41,
    uHigh: 38.6,
    uLow: 36.8,
    uVolume: 3643000,
    change: -2.61,
    changePercent: -6.9108,
    label: 'Apr 15, 20',
    changeOverTime: 0.069413
  },
  {
    date: '2023-04-16',
    open: 37.45,
    close: 34.7,
    high: 37.07,
    low: 33.97,
    volume: 3291147,
    uOpen: 36.75,
    uClose: 34.17,
    uHigh: 38.32,
    uLow: 34.52,
    uVolume: 3249128,
    change: -2.78,
    changePercent: -7.6247,
    label: 'Apr 16, 20',
    changeOverTime: -0.01316
  },
  {
    date: '2023-04-17',
    open: 35.43,
    close: 37.92,
    high: 37.91,
    low: 35.1,
    volume: 3095232,
    uOpen: 36.74,
    uClose: 36.65,
    uHigh: 37.11,
    uLow: 35,
    uVolume: 3093215,
    change: 3.1,
    changePercent: 8.9248,
    label: 'Apr 17, 20',
    changeOverTime: 0.076802
  },
  {
    date: '2023-04-20',
    open: 36,
    close: 37.44,
    high: 37.33,
    low: 35.43,
    volume: 2339418,
    uOpen: 35.28,
    uClose: 36.96,
    uHigh: 38.26,
    uLow: 35.27,
    uVolume: 2396599,
    change: 0.13,
    changePercent: 0.3677,
    label: 'Apr 20, 20',
    changeOverTime: 0.082212
  },
  {
    date: '2023-04-21',
    open: 35.83,
    close: 36.8,
    high: 38.64,
    low: 35.35,
    volume: 1886815,
    uOpen: 36.95,
    uClose: 36.5,
    uHigh: 37.41,
    uLow: 35.52,
    uVolume: 1904771,
    change: -0.27,
    changePercent: -0.7363,
    label: 'Apr 21, 20',
    changeOverTime: 0.071657
  },
  {
    date: '2023-04-22',
    open: 38.43,
    close: 40.6,
    high: 39.54,
    low: 37.54,
    volume: 3184601,
    uOpen: 37.57,
    uClose: 39.46,
    uHigh: 39.79,
    uLow: 38.24,
    uVolume: 3271350,
    change: 2.54,
    changePercent: 7.1218,
    label: 'Apr 22, 20',
    changeOverTime: 0.15192
  },
  {
    date: '2023-04-23',
    open: 41,
    close: 38.93,
    high: 42.15,
    low: 39.19,
    volume: 2867327,
    uOpen: 39.34,
    uClose: 39,
    uHigh: 41.31,
    uLow: 38.94,
    uVolume: 2844288,
    change: -0.39,
    changePercent: -0.9824,
    label: 'Apr 23, 20',
    changeOverTime: 0.13651
  },
  {
    date: '2023-04-24',
    open: 39,
    close: 41.1,
    high: 42.73,
    low: 39.32,
    volume: 3127803,
    uOpen: 39.4,
    uClose: 42.54,
    uHigh: 42.47,
    uLow: 40.12,
    uVolume: 3092452,
    change: 2.39,
    changePercent: 6.2802,
    label: 'Apr 24, 20',
    changeOverTime: 0.21133
  },
  {
    date: '2023-04-27',
    open: 42.13,
    close: 44.29,
    high: 44.09,
    low: 42.1,
    volume: 2594437,
    uOpen: 41.88,
    uClose: 44.49,
    uHigh: 43.54,
    uLow: 41,
    uVolume: 2616165,
    change: 2.35,
    changePercent: 5.9304,
    label: 'Apr 27, 20',
    changeOverTime: 0.283126
  },
  {
    date: '2023-04-28',
    open: 45,
    close: 42.65,
    high: 45.9,
    low: 42.34,
    volume: 2698941,
    uOpen: 46,
    uClose: 43.72,
    uHigh: 47.7,
    uLow: 43.27,
    uVolume: 2645368,
    change: -0.88,
    changePercent: -2.0427,
    label: 'Apr 28, 20',
    changeOverTime: 0.251323
  },
  {
    date: '2023-04-29',
    open: 45.45,
    close: 47.08,
    high: 47.73,
    low: 45.2,
    volume: 3352490,
    uOpen: 45.98,
    uClose: 45.94,
    uHigh: 47.32,
    uLow: 43.33,
    uVolume: 3275150,
    change: 3.57,
    changePercent: 8.2455,
    label: 'Apr 29, 20',
    changeOverTime: 0.363908
  },
  {
    date: '2023-04-30',
    open: 45.41,
    close: 44.13,
    high: 45.55,
    low: 44.7,
    volume: 3313924,
    uOpen: 46.26,
    uClose: 44.45,
    uHigh: 46.42,
    uLow: 44.38,
    uVolume: 3234484,
    change: -1.66,
    changePercent: -3.7303,
    label: 'Apr 30, 20',
    changeOverTime: 0.302418
  },
  {
    date: '2023-05-01',
    open: 42.72,
    close: 43.98,
    high: 44.53,
    low: 41.32,
    volume: 2419428,
    uOpen: 41.79,
    uClose: 42.69,
    uHigh: 43.41,
    uLow: 42.12,
    uVolume: 2406372,
    change: -1.6,
    changePercent: -3.5278,
    label: 'May 1, 20',
    changeOverTime: 0.268163
  },
  {
    date: '2023-05-04',
    open: 42.6,
    close: 43.31,
    high: 43.9,
    low: 41.79,
    volume: 1976993,
    uOpen: 43.55,
    uClose: 43.15,
    uHigh: 43.1,
    uLow: 41.63,
    uVolume: 1942559,
    change: 0.14,
    changePercent: 0.3126,
    label: 'May 4, 20',
    changeOverTime: 0.271869
  },
  {
    date: '2023-05-05',
    open: 45.2,
    close: 44.9,
    high: 45.43,
    low: 43.91,
    volume: 3223418,
    uOpen: 45.6,
    uClose: 43.7,
    uHigh: 45.27,
    uLow: 44.34,
    uVolume: 3121254,
    change: 1.03,
    changePercent: 2.4457,
    label: 'May 5, 20',
    changeOverTime: 0.292188
  },
  {
    date: '2023-05-06',
    open: 44.79,
    close: 45.45,
    high: 45.36,
    low: 45.49,
    volume: 4076870,
    uOpen: 45.23,
    uClose: 44.33,
    uHigh: 45.99,
    uLow: 44.61,
    uVolume: 4088599,
    change: -0.17,
    changePercent: -0.3952,
    label: 'May 6, 20',
    changeOverTime: 0.28799
  },
  {
    date: '2023-05-07',
    open: 45.81,
    close: 50.17,
    high: 50.76,
    low: 45.31,
    volume: 7658071,
    uOpen: 44.72,
    uClose: 50.74,
    uHigh: 51.97,
    uLow: 44.73,
    uVolume: 7416023,
    change: 5.27,
    changePercent: 12.0591,
    label: 'May 7, 20',
    changeOverTime: 0.449954
  },
  {
    date: '2023-05-08',
    open: 52,
    close: 54.13,
    high: 59.73,
    low: 49.77,
    volume: 12790072,
    uOpen: 53,
    uClose: 53.39,
    uHigh: 59.91,
    uLow: 49.74,
    uVolume: 12979282,
    change: 4.45,
    changePercent: 9.088,
    label: 'May 8, 20',
    changeOverTime: 0.59392
  },
  {
    date: '2023-05-11',
    open: 54.19,
    close: 56.37,
    high: 58.24,
    low: 52.2,
    volume: 5254611,
    uOpen: 53.34,
    uClose: 57.27,
    uHigh: 58.18,
    uLow: 53.14,
    uVolume: 5273610,
    change: 2.93,
    changePercent: 5.3909,
    label: 'May 11, 20',
    changeOverTime: 0.664999
  },
  {
    date: '2023-05-12',
    open: 54.26,
    close: 51.44,
    high: 57,
    low: 49.76,
    volume: 8831018,
    uOpen: 53.37,
    uClose: 50.77,
    uHigh: 55,
    uLow: 50.33,
    uVolume: 8794895,
    change: -6.74,
    changePercent: -12.0341,
    label: 'May 12, 20',
    changeOverTime: 0.457483
  },
  {
    date: '2023-05-13',
    open: 47.2,
    close: 47.08,
    high: 49.07,
    low: 46.22,
    volume: 15030187,
    uOpen: 47.5,
    uClose: 48.15,
    uHigh: 49.25,
    uLow: 46.53,
    uVolume: 15153469,
    change: -2.75,
    changePercent: -5.5523,
    label: 'May 13, 20',
    changeOverTime: 0.377454
  },
  {
    date: '2023-05-14',
    open: 46.89,
    close: 47.72,
    high: 49.53,
    low: 46.29,
    volume: 6417268,
    uOpen: 45.45,
    uClose: 48.85,
    uHigh: 49.01,
    uLow: 47.02,
    uVolume: 6351751,
    change: 0.96,
    changePercent: 2.0648,
    label: 'May 14, 20',
    changeOverTime: 0.406023
  },
  {
    date: '2023-05-15',
    open: 49.02,
    close: 50.11,
    high: 51.42,
    low: 47.9,
    volume: 4822039,
    uOpen: 47.9,
    uClose: 49.37,
    uHigh: 51.66,
    uLow: 48.4,
    uVolume: 4941006,
    change: 1.32,
    changePercent: 2.793,
    label: 'May 15, 20',
    changeOverTime: 0.461551
  },
  {
    date: '2023-05-18',
    open: 51.48,
    close: 51.45,
    high: 52.27,
    low: 50.92,
    volume: 4756206,
    uOpen: 51.54,
    uClose: 50.83,
    uHigh: 52.32,
    uLow: 49.8,
    uVolume: 4711934,
    change: 0.85,
    changePercent: 1.763,
    label: 'May 18, 20',
    changeOverTime: 0.477374
  },
  {
    date: '2023-05-19',
    open: 50.84,
    close: 52.51,
    high: 53.1,
    low: 49.51,
    volume: 3311406,
    uOpen: 50.17,
    uClose: 53.13,
    uHigh: 55,
    uLow: 49.62,
    uVolume: 3330267,
    change: 1.54,
    changePercent: 2.9696,
    label: 'May 19, 20',
    changeOverTime: 0.519174
  },
  {
    date: '2023-05-20',
    open: 53.1,
    close: 55.59,
    high: 55.19,
    low: 53.35,
    volume: 3742877,
    uOpen: 54.1,
    uClose: 56.69,
    uHigh: 55.28,
    uLow: 53.95,
    uVolume: 3800781,
    change: 2.98,
    changePercent: 5.9624,
    label: 'May 20, 20',
    changeOverTime: 0.599448
  },
  {
    date: '2023-05-21',
    open: 54.77,
    close: 54.58,
    high: 56.33,
    low: 53.42,
    volume: 2038377,
    uOpen: 56.5,
    uClose: 55.55,
    uHigh: 57.6,
    uLow: 54.38,
    uVolume: 1985188,
    change: 0.11,
    changePercent: 0.2042,
    label: 'May 21, 20',
    changeOverTime: 0.613152
  },
  {
    date: '2023-05-22',
    open: 56.4,
    close: 58.4,
    high: 58.22,
    low: 55.79,
    volume: 5167092,
    uOpen: 56.1,
    uClose: 56.8,
    uHigh: 59.27,
    uLow: 55.8,
    uVolume: 5264772,
    change: 2.38,
    changePercent: 4.3355,
    label: 'May 22, 20',
    changeOverTime: 0.688629
  },
  {
    date: '2023-05-26',
    open: 60.51,
    close: 60.6,
    high: 61.4,
    low: 60.46,
    volume: 4942220,
    uOpen: 58.67,
    uClose: 60.25,
    uHigh: 60.8,
    uLow: 59.64,
    uVolume: 4868289,
    change: 2.47,
    changePercent: 4.2722,
    label: 'May 26, 20',
    changeOverTime: 0.769251
  },
  {
    date: '2023-05-27',
    open: 62,
    close: 59.41,
    high: 61.16,
    low: 58.4,
    volume: 3849615,
    uOpen: 60,
    uClose: 60.9,
    uHigh: 60.8,
    uLow: 58.2,
    uVolume: 3932022,
    change: 0.19,
    changePercent: 0.3173,
    label: 'May 27, 20',
    changeOverTime: 0.759573
  },
  {
    date: '2023-05-28',
    open: 61.3,
    close: 58.4,
    high: 61.11,
    low: 58.31,
    volume: 3510853,
    uOpen: 59.1,
    uClose: 58.8,
    uHigh: 63.13,
    uLow: 59.01,
    uVolume: 3507559,
    change: -1.69,
    changePercent: -2.927,
    label: 'May 28, 20',
    changeOverTime: 0.729463
  },
  {
    date: '2023-05-29',
    open: 59.8,
    close: 59.93,
    high: 59.32,
    low: 57.42,
    volume: 3870684,
    uOpen: 59.4,
    uClose: 58.19,
    uHigh: 59.03,
    uLow: 58.79,
    uVolume: 3805095,
    change: 0.64,
    changePercent: 1.1203,
    label: 'May 29, 20',
    changeOverTime: 0.74926
  },
  {
    date: '2023-06-01',
    open: 59.22,
    close: 60.93,
    high: 60.21,
    low: 59.92,
    volume: 3243097,
    uOpen: 59.54,
    uClose: 59.7,
    uHigh: 59.27,
    uLow: 59.76,
    uVolume: 3291370,
    change: 0.23,
    changePercent: 0.4026,
    label: 'Jun 1, 20',
    changeOverTime: 0.746021
  },
  {
    date: '2023-06-02',
    open: 60,
    close: 62.23,
    high: 61.01,
    low: 58.41,
    volume: 3069398,
    uOpen: 60,
    uClose: 59.55,
    uHigh: 62.33,
    uLow: 59.23,
    uVolume: 3192477,
    change: 1.16,
    changePercent: 2.0728,
    label: 'Jun 2, 20',
    changeOverTime: 0.760133
  },
  {
    date: '2023-06-03',
    open: 62.83,
    close: 64.5,
    high: 63.81,
    low: 61.18,
    volume: 4706713,
    uOpen: 60.46,
    uClose: 62.56,
    uHigh: 64.87,
    uLow: 60.6,
    uVolume: 4606525,
    change: 2.71,
    changePercent: 4.6633,
    label: 'Jun 3, 20',
    changeOverTime: 0.85758
  },
  {
    date: '2023-06-04',
    open: 65.73,
    close: 64.19,
    high: 67.85,
    low: 62.62,
    volume: 5131102,
    uOpen: 64.49,
    uClose: 64.18,
    uHigh: 68.45,
    uLow: 60.71,
    uVolume: 4957445,
    change: -0.68,
    changePercent: -1.0511,
    label: 'Jun 4, 20',
    changeOverTime: 0.85401
  },
  {
    date: '2023-06-05',
    open: 65.96,
    close: 61.41,
    high: 66.62,
    low: 61.37,
    volume: 4104738,
    uOpen: 64.57,
    uClose: 61.02,
    uHigh: 66.73,
    uLow: 61.74,
    uVolume: 4028685,
    change: -2.1,
    changePercent: -3.4418,
    label: 'Jun 5, 20',
    changeOverTime: 0.79106
  },
  {
    date: '2023-06-08',
    open: 65.1,
    close: 64.12,
    high: 64.55,
    low: 62.8,
    volume: 4129459,
    uOpen: 65.15,
    uClose: 62.95,
    uHigh: 64.12,
    uLow: 62.89,
    uVolume: 4025632,
    change: 2.96,
    changePercent: 4.9651,
    label: 'Jun 8, 20',
    changeOverTime: 0.873656
  },
  {
    date: '2023-06-09',
    open: 64.25,
    close: 64.12,
    high: 63.07,
    low: 61.85,
    volume: 2296945,
    uOpen: 62.5,
    uClose: 63.11,
    uHigh: 62.89,
    uLow: 62.58,
    uVolume: 2289795,
    change: -1.11,
    changePercent: -1.7237,
    label: 'Jun 9, 20',
    changeOverTime: 0.825421
  },
  {
    date: '2023-06-10',
    open: 62.59,
    close: 59.89,
    high: 62.72,
    low: 61.08,
    volume: 3571569,
    uOpen: 64.19,
    uClose: 62.42,
    uHigh: 63.72,
    uLow: 60.87,
    uVolume: 3559484,
    change: -1.6,
    changePercent: -2.7274,
    label: 'Jun 10, 20',
    changeOverTime: 0.794154
  },
  {
    date: '2023-06-11',
    open: 57.66,
    close: 57.8,
    high: 59.27,
    low: 57.68,
    volume: 4010088,
    uOpen: 57.79,
    uClose: 56.4,
    uHigh: 60.95,
    uLow: 57.44,
    uVolume: 3961722,
    change: -3.36,
    changePercent: -5.7391,
    label: 'Jun 11, 20',
    changeOverTime: 0.68122
  },
  {
    date: '2023-06-12',
    open: 61.33,
    close: 57.26,
    high: 59.86,
    low: 56.47,
    volume: 2327723,
    uOpen: 60,
    uClose: 59.19,
    uHigh: 59.78,
    uLow: 56.7,
    uVolume: 2324679,
    change: 0.5,
    changePercent: 0.8846,
    label: 'Jun 12, 20',
    changeOverTime: 0.688404
  },
  {
    date: '2023-06-15',
    open: 56.6,
    close: 63,
    high: 62.47,
    low: 56.17,
    volume: 3791551,
    uOpen: 56.7,
    uClose: 60,
    uHigh: 62.86,
    uLow: 58.18,
    uVolume: 3763954,
    change: 3.29,
    changePercent: 5.8257,
    label: 'Jun 15, 20',
    changeOverTime: 0.808185
  },
  {
    date: '2023-06-16',
    open: 65.17,
    close: 60.9,
    high: 64.38,
    low: 59.66,
    volume: 4766798,
    uOpen: 63.87,
    uClose: 61.3,
    uHigh: 63.07,
    uLow: 59.03,
    uVolume: 4848495,
    change: -0.9,
    changePercent: -1.5,
    label: 'Jun 16, 20',
    changeOverTime: 0.767913
  },
  {
    date: '2023-06-17',
    open: 62.2,
    close: 62.07,
    high: 62.9,
    low: 62.19,
    volume: 2559429,
    uOpen: 61.9,
    uClose: 62.78,
    uHigh: 64.1,
    uLow: 61.51,
    uVolume: 2612826,
    change: 1.71,
    changePercent: 2.775,
    label: 'Jun 17, 20',
    changeOverTime: 0.817332
  },
  {
    date: '2023-06-18',
    open: 62.66,
    close: 61.68,
    high: 64,
    low: 62,
    volume: 1639482,
    uOpen: 62.36,
    uClose: 63.2,
    uHigh: 64,
    uLow: 60.36,
    uVolume: 1661269,
    change: 0.6,
    changePercent: 1.0008,
    label: 'Jun 18, 20',
    changeOverTime: 0.849587
  },
  {
    date: '2023-06-19',
    open: 63.93,
    close: 64.04,
    high: 64.86,
    low: 63.1,
    volume: 5255484,
    uOpen: 63.06,
    uClose: 64.53,
    uHigh: 66.17,
    uLow: 62.1,
    uVolume: 5082785,
    change: 0.91,
    changePercent: 1.511,
    label: 'Jun 19, 20',
    changeOverTime: 0.843871
  },
  {
    date: '2023-06-22',
    open: 65.31,
    close: 63.27,
    high: 64,
    low: 61.9,
    volume: 4696624,
    uOpen: 65.96,
    uClose: 61.62,
    uHigh: 65,
    uLow: 62.2,
    uVolume: 4777935,
    change: -1.64,
    changePercent: -2.5853,
    label: 'Jun 22, 20',
    changeOverTime: 0.809858
  },
  {
    date: '2023-06-23',
    open: 63.47,
    close: 64.08,
    high: 64.36,
    low: 63.7,
    volume: 3248388,
    uOpen: 61.48,
    uClose: 63.42,
    uHigh: 64.71,
    uLow: 61.9,
    uVolume: 3148849,
    change: 2.49,
    changePercent: 4.0413,
    label: 'Jun 23, 20',
    changeOverTime: 0.87969
  },
  {
    date: '2023-06-24',
    open: 62.54,
    close: 61,
    high: 65.04,
    low: 60.78,
    volume: 3845966,
    uOpen: 63.76,
    uClose: 62.2,
    uHigh: 65.56,
    uLow: 59.01,
    uVolume: 3785171,
    change: -3.96,
    changePercent: -6.2079,
    label: 'Jun 24, 20',
    changeOverTime: 0.763668
  },
  {
    date: '2023-06-25',
    open: 60.24,
    close: 60.26,
    high: 61.96,
    low: 60.29,
    volume: 2217363,
    uOpen: 61.02,
    uClose: 60.08,
    uHigh: 61.07,
    uLow: 60.39,
    uVolume: 2213705,
    change: -0.23,
    changePercent: -0.377,
    label: 'Jun 25, 20',
    changeOverTime: 0.783979
  },
  {
    date: '2023-06-26',
    open: 60.92,
    close: 58.14,
    high: 61.82,
    low: 56.48,
    volume: 5218141,
    uOpen: 60.55,
    uClose: 57.06,
    uHigh: 61.07,
    uLow: 54.85,
    uVolume: 5349003,
    change: -3.6,
    changePercent: -5.9271,
    label: 'Jun 26, 20',
    changeOverTime: 0.662021
  },
  {
    date: '2023-06-29',
    open: 57.05,
    close: 58.47,
    high: 59.87,
    low: 55.44,
    volume: 4039981,
    uOpen: 58.39,
    uClose: 58,
    uHigh: 58.54,
    uLow: 54.82,
    uVolume: 3997351,
    change: 1.57,
    changePercent: 2.771,
    label: 'Jun 29, 20',
    changeOverTime: 0.71128
  },
  {
    date: '2023-06-30',
    open: 58,
    close: 58.63,
    high: 60.54,
    low: 57.2,
    volume: 2326404,
    uOpen: 59,
    uClose: 57.99,
    uHigh: 59.78,
    uLow: 58.37,
    uVolume: 2240398,
    change: 0.49,
    changePercent: 0.867,
    label: 'Jun 30, 20',
    changeOverTime: 0.738371
  },
  {
    date: '2023-07-01',
    open: 58.5,
    close: 61.63,
    high: 61.94,
    low: 59.06,
    volume: 2849011,
    uOpen: 57.7,
    uClose: 58.96,
    uHigh: 61.96,
    uLow: 57.68,
    uVolume: 2838214,
    change: 1.3,
    changePercent: 2.3574,
    label: 'Jul 1, 20',
    changeOverTime: 0.765241
  },
  {
    date: '2023-07-02',
    open: 61,
    close: 62.84,
    high: 61.3,
    low: 61.91,
    volume: 2206319,
    uOpen: 60,
    uClose: 62.22,
    uHigh: 62.1,
    uLow: 61.28,
    uVolume: 2217723,
    change: 1.06,
    changePercent: 1.8074,
    label: 'Jul 2, 20',
    changeOverTime: 0.783944
  },
  {
    date: '2023-07-06',
    open: 62.74,
    close: 61.56,
    high: 64.3,
    low: 60.86,
    volume: 2754405,
    uOpen: 63.46,
    uClose: 64.13,
    uHigh: 64.91,
    uLow: 62.73,
    uVolume: 2695276,
    change: 1.34,
    changePercent: 2.2915,
    label: 'Jul 6, 20',
    changeOverTime: 0.840216
  },
  {
    date: '2023-07-07',
    open: 63.57,
    close: 63.49,
    high: 67.29,
    low: 62.28,
    volume: 5659806,
    uOpen: 63.21,
    uClose: 65.54,
    uHigh: 69.74,
    uLow: 62.92,
    uVolume: 5692975,
    change: 1.46,
    changePercent: 2.4021,
    label: 'Jul 7, 20',
    changeOverTime: 0.882124
  },
  {
    date: '2023-07-08',
    open: 65,
    close: 64.91,
    high: 65.03,
    low: 63.53,
    volume: 3339116,
    uOpen: 64.8,
    uClose: 65.03,
    uHigh: 66.94,
    uLow: 63.38,
    uVolume: 3252388,
    change: 0.92,
    changePercent: 1.5026,
    label: 'Jul 8, 20',
    changeOverTime: 0.881969
  },
  {
    date: '2023-07-09',
    open: 64.92,
    close: 63.21,
    high: 67.45,
    low: 64.61,
    volume: 2386964,
    uOpen: 66.65,
    uClose: 63.72,
    uHigh: 66.18,
    uLow: 63.33,
    uVolume: 2456595,
    change: -0.62,
    changePercent: -1.0143,
    label: 'Jul 9, 20',
    changeOverTime: 0.87532
  },
  {
    date: '2023-07-10',
    open: 63.53,
    close: 62.95,
    high: 64.5,
    low: 64.21,
    volume: 1649858,
    uOpen: 66.05,
    uClose: 64.75,
    uHigh: 66.42,
    uLow: 61.59,
    uVolume: 1689025,
    change: -0.77,
    changePercent: -1.2232,
    label: 'Jul 10, 20',
    changeOverTime: 0.845413
  },
  {
    date: '2023-07-13',
    open: 64.1,
    close: 58.8,
    high: 65.39,
    low: 60.1,
    volume: 3002661,
    uOpen: 65.7,
    uClose: 60.1,
    uHigh: 63.4,
    uLow: 60.56,
    uVolume: 2969132,
    change: -4.11,
    changePercent: -6.773,
    label: 'Jul 13, 20',
    changeOverTime: 0.741617
  },
  {
    date: '2023-07-14',
    open: 61,
    close: 58.01,
    high: 61.2,
    low: 58.64,
    volume: 3964394,
    uOpen: 58.98,
    uClose: 60.63,
    uHigh: 61.79,
    uLow: 58.28,
    uVolume: 3846104,
    change: -0.36,
    changePercent: -0.6105,
    label: 'Jul 14, 20',
    changeOverTime: 0.717899
  },
  {
    date: '2023-07-15',
    open: 60.95,
    close: 60.05,
    high: 62.4,
    low: 58.87,
    volume: 1867214,
    uOpen: 61.8,
    uClose: 59.13,
    uHigh: 59.9,
    uLow: 59.81,
    uVolume: 1875647,
    change: 1.27,
    changePercent: 2.1878,
    label: 'Jul 15, 20',
    changeOverTime: 0.780549
  },
  {
    date: '2023-07-16',
    open: 59.35,
    close: 62.54,
    high: 63.1,
    low: 59.42,
    volume: 3470963,
    uOpen: 59.64,
    uClose: 63.7,
    uHigh: 65.92,
    uLow: 58.32,
    uVolume: 3478546,
    change: 3.23,
    changePercent: 5.446,
    label: 'Jul 16, 20',
    changeOverTime: 0.878551
  },
  {
    date: '2023-07-17',
    open: 63.49,
    close: 65.29,
    high: 66.99,
    low: 63.11,
    volume: 2919970,
    uOpen: 65.18,
    uClose: 66.72,
    uHigh: 66.89,
    uLow: 64.06,
    uVolume: 2940978,
    change: 2.11,
    changePercent: 3.3657,
    label: 'Jul 17, 20',
    changeOverTime: 0.944867
  },
  {
    date: '2023-07-20',
    open: 65.74,
    close: 66.56,
    high: 69.8,
    low: 65.46,
    volume: 4412290,
    uOpen: 66.07,
    uClose: 66.45,
    uHigh: 68.3,
    uLow: 66.56,
    uVolume: 4499298,
    change: 2,
    changePercent: 3.1542,
    label: 'Jul 20, 20',
    changeOverTime: 0.982897
  },
  {
    date: '2023-07-21',
    open: 67.99,
    close: 66.73,
    high: 68.62,
    low: 66.41,
    volume: 2899362,
    uOpen: 68.46,
    uClose: 65.36,
    uHigh: 70.69,
    uLow: 67.61,
    uVolume: 2868567,
    change: -1.23,
    changePercent: -1.9104,
    label: 'Jul 21, 20',
    changeOverTime: 0.929798
  },
  {
    date: '2023-07-22',
    open: 66.64,
    close: 70.2,
    high: 69.51,
    low: 65.2,
    volume: 2635450,
    uOpen: 65.34,
    uClose: 70.5,
    uHigh: 68.62,
    uLow: 65.6,
    uVolume: 2679519,
    change: 2.47,
    changePercent: 3.6666,
    label: 'Jul 22, 20',
    changeOverTime: 1.042607
  },
  {
    date: '2023-07-23',
    open: 68.08,
    close: 66,
    high: 69.6,
    low: 65.69,
    volume: 4415555,
    uOpen: 68.86,
    uClose: 65.7,
    uHigh: 70,
    uLow: 64.86,
    uVolume: 4457942,
    change: -2,
    changePercent: -2.958,
    label: 'Jul 23, 20',
    changeOverTime: 0.94552
  },
  {
    date: '2023-07-24',
    open: 64.9,
    close: 68.3,
    high: 68.52,
    low: 65.34,
    volume: 2701141,
    uOpen: 67.57,
    uClose: 67.89,
    uHigh: 68.8,
    uLow: 67.09,
    uVolume: 2650056,
    change: 0.22,
    changePercent: 0.3455,
    label: 'Jul 24, 20',
    changeOverTime: 0.987518
  },
  {
    date: '2023-07-27',
    open: 67.71,
    close: 68.08,
    high: 68.83,
    low: 67.63,
    volume: 4047055,
    uOpen: 70.07,
    uClose: 67.18,
    uHigh: 68.39,
    uLow: 67.62,
    uVolume: 3978981,
    change: 1.24,
    changePercent: 1.8214,
    label: 'Jul 27, 20',
    changeOverTime: 1.012459
  },
  {
    date: '2023-07-28',
    open: 69.08,
    close: 65,
    high: 69.09,
    low: 66.66,
    volume: 2171562,
    uOpen: 66.51,
    uClose: 66,
    uHigh: 70.24,
    uLow: 65.35,
    uVolume: 2225164,
    change: -1.93,
    changePercent: -2.9382,
    label: 'Jul 28, 20',
    changeOverTime: 0.965657
  },
  {
    date: '2023-07-29',
    open: 66.69,
    close: 68.47,
    high: 68,
    low: 67.65,
    volume: 2041172,
    uOpen: 67.54,
    uClose: 67.73,
    uHigh: 68.39,
    uLow: 67.13,
    uVolume: 2054921,
    change: 1.54,
    changePercent: 2.2931,
    label: 'Jul 29, 20',
    changeOverTime: 0.98395
  },
  {
    date: '2023-07-30',
    open: 68.27,
    close: 70.3,
    high: 70.73,
    low: 66.2,
    volume: 3975033,
    uOpen: 68.25,
    uClose: 69.9,
    uHigh: 70.42,
    uLow: 66.3,
    uVolume: 3983668,
    change: 3.48,
    changePercent: 5.3779,
    label: 'Jul 30, 20',
    changeOverTime: 1.102867
  },
  {
    date: '2023-07-31',
    open: 73.3,
    close: 71.07,
    high: 74,
    low: 67.91,
    volume: 3072595,
    uOpen: 73.78,
    uClose: 71.02,
    uHigh: 74,
    uLow: 67.93,
    uVolume: 2941945,
    change: -1.54,
    changePercent: -2.2318,
    label: 'Jul 31, 20',
    changeOverTime: 1.044144
  },
  {
    date: '2023-08-03',
    open: 71.21,
    close: 68.96,
    high: 70.82,
    low: 67.13,
    volume: 2769545,
    uOpen: 70.07,
    uClose: 70.28,
    uHigh: 72.81,
    uLow: 67.74,
    uVolume: 2680288,
    change: -0.45,
    changePercent: -0.673,
    label: 'Aug 3, 20',
    changeOverTime: 1.038481
  },
  {
    date: '2023-08-04',
    open: 71.9,
    close: 70.89,
    high: 71.2,
    low: 69.53,
    volume: 3323465,
    uOpen: 71.8,
    uClose: 71.42,
    uHigh: 71.6,
    uLow: 70.72,
    uVolume: 3296835,
    change: 0.3,
    changePercent: 0.459,
    label: 'Aug 4, 20',
    changeOverTime: 1.056066
  },
  {
    date: '2023-08-05',
    open: 69.22,
    close: 72.1,
    high: 72.18,
    low: 69.33,
    volume: 2617083,
    uOpen: 70.97,
    uClose: 72.5,
    uHigh: 72.73,
    uLow: 68.33,
    uVolume: 2604569,
    change: 2.47,
    changePercent: 3.502,
    label: 'Aug 5, 20',
    changeOverTime: 1.13668
  },
  {
    date: '2023-08-06',
    open: 72.55,
    close: 73.15,
    high: 73.6,
    low: 72.09,
    volume: 6589767,
    uOpen: 71.12,
    uClose: 72.55,
    uHigh: 75.3,
    uLow: 72.25,
    uVolume: 6649827,
    change: 0.84,
    changePercent: 1.1665,
    label: 'Aug 6, 20',
    changeOverTime: 1.128941
  },
  {
    date: '2023-08-07',
    open: 81.65,
    close: 82.64,
    high: 87.7,
    low: 76,
    volume: 27655931,
    uOpen: 79.51,
    uClose: 80.28,
    uHigh: 85.5,
    uLow: 77,
    uVolume: 28649161,
    change: 8.52,
    changePercent: 12.0405,
    label: 'Aug 7, 20',
    changeOverTime: 1.365881
  },
  {
    date: '2023-08-10',
    open: 83.03,
    close: 79.69,
    high: 82.8,
    low: 78.6,
    volume: 4119285,
    uOpen: 82.81,
    uClose: 80.73,
    uHigh: 81.54,
    uLow: 82.2,
    uVolume: 4165296,
    change: -0.43,
    changePercent: -0.5444,
    label: 'Aug 10, 20',
    changeOverTime: 1.398748
  },
  {
    date: '2023-08-11',
    open: 81.44,
    close: 76.78,
    high: 80.97,
    low: 76.4,
    volume: 4190930,
    uOpen: 82.33,
    uClose: 79.04,
    uHigh: 80.08,
    uLow: 76.77,
    uVolume: 4129386,
    change: -4.07,
    changePercent: -5.0925,
    label: 'Aug 11, 20',
    changeOverTime: 1.288909
  },
  {
    date: '2023-08-12',
    open: 75.6,
    close: 77,
    high: 82.32,
    low: 77.36,
    volume: 3404497,
    uOpen: 77.03,
    uClose: 79,
    uHigh: 79.56,
    uLow: 77.52,
    uVolume: 3312565,
    change: 0.68,
    changePercent: 0.8949,
    label: 'Aug 12, 20',
    changeOverTime: 1.261838
  },
  {
    date: '2023-08-13',
    open: 78.31,
    close: 75.2,
    high: 80.61,
    low: 77.17,
    volume: 4622309,
    uOpen: 78.85,
    uClose: 77.93,
    uHigh: 78.58,
    uLow: 77.5,
    uVolume: 4622267,
    change: -1.59,
    changePercent: -2.1312,
    label: 'Aug 13, 20',
    changeOverTime: 1.222435
  },
  {
    date: '2023-08-14',
    open: 75.29,
    close: 78.85,
    high: 79.73,
    low: 76.72,
    volume: 3044461,
    uOpen: 74.71,
    uClose: 77.48,
    uHigh: 79.59,
    uLow: 76.03,
    uVolume: 3101444,
    change: 2.3,
    changePercent: 3.0697,
    label: 'Aug 14, 20',
    changeOverTime: 1.308327
  },
  {
    date: '2023-08-17',
    open: 80.42,
    close: 83.21,
    high: 83,
    low: 78.8,
    volume: 3460224,
    uOpen: 79.97,
    uClose: 83.12,
    uHigh: 83.3,
    uLow: 78.55,
    uVolume: 3493198,
    change: 3.16,
    changePercent: 4.1797,
    label: 'Aug 17, 20',
    changeOverTime: 1.397892
  },
  {
    date: '2023-08-18',
    open: 83.6,
    close: 84.49,
    high: 84.63,
    low: 80.36,
    volume: 3657034,
    uOpen: 81,
    uClose: 85.6,
    uHigh: 83.61,
    uLow: 81.19,
    uVolume: 3571098,
    change: 2.61,
    changePercent: 3.2646,
    label: 'Aug 18, 20',
    changeOverTime: 1.503208
  },
  {
    date: '2023-08-19',
    open: 85.18,
    close: 85.93,
    high: 86.82,
    low: 84.61,
    volume: 3698565,
    uOpen: 83.87,
    uClose: 87.32,
    uHigh: 86.62,
    uLow: 83.43,
    uVolume: 3745594,
    change: 1.54,
    changePercent: 1.8827,
    label: 'Aug 19, 20',
    changeOverTime: 1.547065
  },
  {
    date: '2023-08-20',
    open: 84.25,
    close: 85.23,
    high: 88,
    low: 85.83,
    volume: 2610434,
    uOpen: 85.42,
    uClose: 83.87,
    uHigh: 86,
    uLow: 83.22,
    uVolume: 2538611,
    change: -1.01,
    changePercent: -1.173,
    label: 'Aug 20, 20',
    changeOverTime: 1.488569
  },
  {
    date: '2023-08-21',
    open: 83.41,
    close: 87.2,
    high: 86.13,
    low: 86.88,
    volume: 1831305,
    uOpen: 86.5,
    uClose: 83.7,
    uHigh: 84.42,
    uLow: 85.06,
    uVolume: 1823325,
    change: 0.37,
    changePercent: 0.4253,
    label: 'Aug 21, 20',
    changeOverTime: 1.523688
  },
  {
    date: '2023-08-24',
    open: 86.62,
    close: 87.4,
    high: 87.87,
    low: 83.85,
    volume: 2074975,
    uOpen: 87.02,
    uClose: 84.8,
    uHigh: 85.4,
    uLow: 82.28,
    uVolume: 2041726,
    change: 0.4,
    changePercent: 0.4954,
    label: 'Aug 24, 20',
    changeOverTime: 1.48216
  },
  {
    date: '2023-08-25',
    open: 86.27,
    close: 84.51,
    high: 87.16,
    low: 82.34,
    volume: 2091374,
    uOpen: 84.48,
    uClose: 84.35,
    uHigh: 87.17,
    uLow: 82.54,
    uVolume: 2131332,
    change: -0.81,
    changePercent: -0.9366,
    label: 'Aug 25, 20',
    changeOverTime: 1.511231
  },
  {
    date: '2023-08-26',
    open: 83.59,
    close: 84.32,
    high: 89.5,
    low: 85.6,
    volume: 3704448,
    uOpen: 85.45,
    uClose: 87.57,
    uHigh: 87.65,
    uLow: 86,
    uVolume: 3856979,
    change: 1.02,
    changePercent: 1.2578,
    label: 'Aug 26, 20',
    changeOverTime: 1.54771
  },
  {
    date: '2023-08-27',
    open: 85.62,
    close: 85.59,
    high: 90.17,
    low: 85.58,
    volume: 2620131,
    uOpen: 84.97,
    uClose: 85.6,
    uHigh: 88.81,
    uLow: 86.92,
    uVolume: 2540983,
    change: 1.41,
    changePercent: 1.6747,
    label: 'Aug 27, 20',
    changeOverTime: 1.523364
  },
  {
    date: '2023-08-28',
    open: 86.45,
    close: 91.39,
    high: 91.03,
    low: 86.27,
    volume: 1862283,
    uOpen: 88.29,
    uClose: 89.87,
    uHigh: 90.78,
    uLow: 87.59,
    uVolume: 1886349,
    change: 2.12,
    changePercent: 2.4947,
    label: 'Aug 28, 20',
    changeOverTime: 1.588418
  },
  {
    date: '2023-08-31',
    open: 88.57,
    close: 87.82,
    high: 91.3,
    low: 88.14,
    volume: 2186316,
    uOpen: 90.17,
    uClose: 87.42,
    uHigh: 87.41,
    uLow: 87.3,
    uVolume: 2181231,
    change: -1.63,
    changePercent: -1.7874,
    label: 'Aug 31, 20',
    changeOverTime: 1.608187
  },
  {
    date: '2023-09-01',
    open: 89.7,
    close: 91.69,
    high: 92.53,
    low: 85.73,
    volume: 2470735,
    uOpen: 88.2,
    uClose: 89.97,
    uHigh: 89.9,
    uLow: 89.22,
    uVolume: 2407566,
    change: 2.75,
    changePercent: 3.136,
    label: 'Sep 1, 20',
    changeOverTime: 1.645636
  },
  {
    date: '2023-09-02',
    open: 93.79,
    close: 89.43,
    high: 96.44,
    low: 91.96,
    volume: 4704602,
    uOpen: 91.88,
    uClose: 91.72,
    uHigh: 94.5,
    uLow: 88.92,
    uVolume: 4700253,
    change: 0.84,
    changePercent: 0.9801,
    label: 'Sep 2, 20',
    changeOverTime: 1.64721
  },
  {
    date: '2023-09-03',
    open: 90.2,
    close: 85.69,
    high: 90.08,
    low: 82.69,
    volume: 3428732,
    uOpen: 91.4,
    uClose: 87.74,
    uHigh: 92.32,
    uLow: 85.11,
    uVolume: 3439760,
    change: -4,
    changePercent: -4.4538,
    label: 'Sep 3, 20',
    changeOverTime: 1.593497
  },
  {
    date: '2023-09-04',
    open: 88.33,
    close: 86.94,
    high: 86.3,
    low: 79.17,
    volume: 3390869,
    uOpen: 86.4,
    uClose: 86.42,
    uHigh: 89.79,
    uLow: 81.02,
    uVolume: 3394052,
    change: -1.59,
    changePercent: -1.8638,
    label: 'Sep 4, 20',
    changeOverTime: 1.507897
  },
  {
    date: '2023-09-08',
    open: 82.27,
    close: 81.7,
    high: 85.67,
    low: 81.62,
    volume: 2765641,
    uOpen: 80.97,
    uClose: 80.84,
    uHigh: 88.21,
    uLow: 83.31,
    uVolume: 2752630,
    change: -3.24,
    changePercent: -4.0236,
    label: 'Sep 8, 20',
    changeOverTime: 1.453855
  },
  {
    date: '2023-09-09',
    open: 82.4,
    close: 84.16,
    high: 85.16,
    low: 80.5,
    volume: 3337068,
    uOpen: 83.93,
    uClose: 82.99,
    uHigh: 87.7,
    uLow: 82.1,
    uVolume: 3394890,
    change: 1.71,
    changePercent: 2.109,
    label: 'Sep 9, 20',
    changeOverTime: 1.479008
  },
  {
    date: '2023-09-10',
    open: 86.09,
    close: 86.42,
    high: 87.82,
    low: 82.17,
    volume: 2942550,
    uOpen: 86.53,
    uClose: 86.1,
    uHigh: 86.77,
    uLow: 82.04,
    uVolume: 3059185,
    change: 0.45,
    changePercent: 0.5557,
    label: 'Sep 10, 20',
    changeOverTime: 1.497985
  },
  {
    date: '2023-09-11',
    open: 86.96,
    close: 87.31,
    high: 86.84,
    low: 86.21,
    volume: 3087711,
    uOpen: 85.44,
    uClose: 89.45,
    uHigh: 89.71,
    uLow: 84.51,
    uVolume: 3061715,
    change: 2.67,
    changePercent: 3.3003,
    label: 'Sep 11, 20',
    changeOverTime: 1.599546
  },
  {
    date: '2023-09-14',
    open: 91.22,
    close: 91.26,
    high: 91.77,
    low: 87.1,
    volume: 3291760,
    uOpen: 88.05,
    uClose: 90.87,
    uHigh: 91.32,
    uLow: 87.65,
    uVolume: 3309967,
    change: 1.8,
    changePercent: 2.1932,
    label: 'Sep 14, 20',
    changeOverTime: 1.64021
  },
  {
    date: '2023-09-15',
    open: 88.02,
    close: 95.37,
    high: 96.84,
    low: 90.07,
    volume: 5720465,
    uOpen: 91.25,
    uClose: 93.28,
    uHigh: 96.78,
    uLow: 88.21,
    uVolume: 5614291,
    change: 4.06,
    changePercent: 4.7012,
    label: 'Sep 15, 20',
    changeOverTime: 1.765879
  },
  {
    date: '2023-09-16',
    open: 94.95,
    close: 96.71,
    high: 97.17,
    low: 92.1,
    volume: 6895440,
    uOpen: 95.95,
    uClose: 95.88,
    uHigh: 98.43,
    uLow: 93.51,
    uVolume: 6777002,
    change: 3.82,
    changePercent: 4.29,
    label: 'Sep 16, 20',
    changeOverTime: 1.81224
  },
  {
    date: '2023-09-17',
    open: 95.3,
    close: 99.24,
    high: 97,
    low: 92.94,
    volume: 5239616,
    uOpen: 94.9,
    uClose: 101.24,
    uHigh: 101,
    uLow: 95.47,
    uVolume: 5424180,
    change: 1.48,
    changePercent: 1.5575,
    label: 'Sep 17, 20',
    changeOverTime: 1.9374
  },
  {
    date: '2023-09-18',
    open: 102.08,
    close: 97.23,
    high: 101,
    low: 93.57,
    volume: 4845904,
    uOpen: 101.46,
    uClose: 97.99,
    uHigh: 100,
    uLow: 93.29,
    uVolume: 4842493,
    change: -0.58,
    changePercent: -0.6073,
    label: 'Sep 18, 20',
    changeOverTime: 1.919154
  },
  {
    date: '2023-09-21',
    open: 97.39,
    close: 99.27,
    high: 99.07,
    low: 93.85,
    volume: 3884415,
    uOpen: 96.21,
    uClose: 96.77,
    uHigh: 99.84,
    uLow: 94.08,
    uVolume: 3972011,
    change: -0.16,
    changePercent: -0.1712,
    label: 'Sep 21, 20',
    changeOverTime: 1.85587
  },
  {
    date: '2023-09-22',
    open: 99.32,
    close: 99.33,
    high: 100.34,
    low: 95.01,
    volume: 3742355,
    uOpen: 99.1,
    uClose: 101.55,
    uHigh: 104.19,
    uLow: 97.01,
    uVolume: 3672388,
    change: 3.49,
    changePercent: 3.66,
    label: 'Sep 22, 20',
    changeOverTime: 1.942257
  },
  {
    date: '2023-09-23',
    open: 102,
    close: 98.67,
    high: 105.57,
    low: 96.32,
    volume: 3731500,
    uOpen: 102,
    uClose: 97.21,
    uHigh: 102.95,
    uLow: 96.55,
    uVolume: 3828640,
    change: -2.9,
    changePercent: -2.9555,
    label: 'Sep 23, 20',
    changeOverTime: 1.856195
  },
  {
    date: '2023-09-24',
    open: 97.08,
    close: 97.89,
    high: 104.27,
    low: 97.3,
    volume: 2809754,
    uOpen: 96.71,
    uClose: 98.7,
    uHigh: 104.94,
    uLow: 97.3,
    uVolume: 2786988,
    change: 1.55,
    changePercent: 1.562,
    label: 'Sep 24, 20',
    changeOverTime: 1.972281
  },
  {
    date: '2023-09-25',
    open: 104.09,
    close: 106.15,
    high: 105.36,
    low: 101.59,
    volume: 2709590,
    uOpen: 102.8,
    uClose: 102.47,
    uHigh: 103.57,
    uLow: 101.24,
    uVolume: 2647857,
    change: 3.59,
    changePercent: 3.55,
    label: 'Sep 25, 20',
    changeOverTime: 1.998297
  },
  {
    date: '2023-09-28',
    open: 104.93,
    close: 101.81,
    high: 103.72,
    low: 102.09,
    volume: 2791773,
    uOpen: 104.9,
    uClose: 103.87,
    uHigh: 103.71,
    uLow: 101.43,
    uVolume: 2736799,
    change: -0.11,
    changePercent: -0.1089,
    label: 'Sep 28, 20',
    changeOverTime: 2.037776
  },
  {
    date: '2023-09-29',
    open: 104.3,
    close: 102.25,
    high: 107.81,
    low: 103.99,
    volume: 1805648,
    uOpen: 104.23,
    uClose: 103.25,
    uHigh: 106.43,
    uLow: 100.28,
    uVolume: 1817865,
    change: 0.27,
    changePercent: 0.259,
    label: 'Sep 29, 20',
    changeOverTime: 2.017937
  },
  {
    date: '2023-09-30',
    open: 103.47,
    close: 106.58,
    high: 104.43,
    low: 102.73,
    volume: 3579571,
    uOpen: 104.35,
    uClose: 102.6,
    uHigh: 107.74,
    uLow: 105.64,
    uVolume: 3517357,
    change: 0.17,
    changePercent: 0.173,
    label: 'Sep 30, 20',
    changeOverTime: 2.073814
  },
  {
    date: '2023-10-01',
    open: 105.3,
    close: 108.43,
    high: 108.94,
    low: 104.2,
    volume: 3736880,
    uOpen: 104.35,
    uClose: 111.55,
    uHigh: 110.89,
    uLow: 102.9,
    uVolume: 3632715,
    change: 6.22,
    changePercent: 5.949,
    label: 'Oct 1, 20',
    changeOverTime: 2.220223
  },
  {
    date: '2023-10-02',
    open: 104.27,
    close: 108.42,
    high: 111.95,
    low: 105.75,
    volume: 2259231,
    uOpen: 105.21,
    uClose: 111.55,
    uHigh: 114.42,
    uLow: 107.33,
    uVolume: 2309381,
    change: 0.65,
    changePercent: 0.6341,
    label: 'Oct 2, 20',
    changeOverTime: 2.242286
  },
  {
    date: '2023-10-05',
    open: 111.88,
    close: 111.63,
    high: 115.55,
    low: 113.14,
    volume: 2483078,
    uOpen: 109.83,
    uClose: 112.33,
    uHigh: 112.54,
    uLow: 112.8,
    uVolume: 2520390,
    change: 2.01,
    changePercent: 1.8416,
    label: 'Oct 5, 20',
    changeOverTime: 2.346159
  },
  {
    date: '2023-10-06',
    open: 114.58,
    close: 106.13,
    high: 115.03,
    low: 108.26,
    volume: 3431045,
    uOpen: 110.71,
    uClose: 107.3,
    uHigh: 114.13,
    uLow: 105.65,
    uVolume: 3440514,
    change: -5.27,
    changePercent: -4.81,
    label: 'Oct 6, 20',
    changeOverTime: 2.126056
  },
  {
    date: '2023-10-07',
    open: 108.75,
    close: 105.61,
    high: 108.37,
    low: 106.8,
    volume: 3617091,
    uOpen: 110.85,
    uClose: 109.74,
    uHigh: 109.59,
    uLow: 108.1,
    uVolume: 3650700,
    change: 0.53,
    changePercent: 0.5007,
    label: 'Oct 7, 20',
    changeOverTime: 2.200404
  },
  {
    date: '2023-10-08',
    open: 109.15,
    close: 105.24,
    high: 107.04,
    low: 106.61,
    volume: 2396645,
    uOpen: 111.59,
    uClose: 107.75,
    uHigh: 111.5,
    uLow: 105.4,
    uVolume: 2344767,
    change: -0.48,
    changePercent: -0.4479,
    label: 'Oct 8, 20',
    changeOverTime: 2.14279
  },
  {
    date: '2023-10-09',
    open: 105.63,
    close: 107.84,
    high: 107.6,
    low: 107.09,
    volume: 1928629,
    uOpen: 106.79,
    uClose: 106.12,
    uHigh: 107.6,
    uLow: 106.61,
    uVolume: 1909490,
    change: -0.79,
    changePercent: -0.7652,
    label: 'Oct 9, 20',
    changeOverTime: 2.162448
  },
  {
    date: '2023-10-12',
    open: 109.75,
    close: 102,
    high: 109.4,
    low: 104.75,
    volume: 2618535,
    uOpen: 107.75,
    uClose: 104.64,
    uHigh: 110.03,
    uLow: 100.29,
    uVolume: 2509132,
    change: -3.39,
    changePercent: -3.2343,
    label: 'Oct 12, 20',
    changeOverTime: 2.010983
  },
  {
    date: '2023-10-13',
    open: 104.38,
    close: 105.9,
    high: 106.04,
    low: 100.05,
    volume: 2463768,
    uOpen: 105.7,
    uClose: 101.4,
    uHigh: 106.09,
    uLow: 104.18,
    uVolume: 2481854,
    change: 0.2,
    changePercent: 0.1909,
    label: 'Oct 13, 20',
    changeOverTime: 2.040108
  },
  {
    date: '2023-10-14',
    open: 104.7,
    close: 103.15,
    high: 109.21,
    low: 101.55,
    volume: 2611266,
    uOpen: 104.4,
    uClose: 105.87,
    uHigh: 105.52,
    uLow: 106.15,
    uVolume: 2603572,
    change: 1.72,
    changePercent: 1.6702,
    label: 'Oct 14, 20',
    changeOverTime: 2.083144
  },
  {
    date: '2023-10-15',
    open: 103.2,
    close: 102.79,
    high: 110.86,
    low: 103.8,
    volume: 3572217,
    uOpen: 103,
    uClose: 104.09,
    uHigh: 111.14,
    uLow: 104.1,
    uVolume: 3525170,
    change: -1.05,
    changePercent: -1.0231,
    label: 'Oct 15, 20',
    changeOverTime: 2.106425
  },
  {
    date: '2023-10-16',
    open: 107.16,
    close: 99.12,
    high: 106.36,
    low: 101.83,
    volume: 3956866,
    uOpen: 106.57,
    uClose: 98.99,
    uHigh: 105.33,
    uLow: 101.68,
    uVolume: 3932165,
    change: -4.92,
    changePercent: -4.8453,
    label: 'Oct 16, 20',
    changeOverTime: 1.884921
  },
  {
    date: '2023-10-19',
    open: 100.63,
    close: 98.52,
    high: 102.32,
    low: 101,
    volume: 2837884,
    uOpen: 102.79,
    uClose: 99.1,
    uHigh: 103.23,
    uLow: 97.8,
    uVolume: 2861952,
    change: 1.39,
    changePercent: 1.4317,
    label: 'Oct 19, 20',
    changeOverTime: 1.946531
  },
  {
    date: '2023-10-20',
    open: 99.34,
    close: 102.75,
    high: 104.89,
    low: 98.24,
    volume: 2793832,
    uOpen: 100.07,
    uClose: 102.63,
    uHigh: 102.15,
    uLow: 100.04,
    uVolume: 2776911,
    change: -0.02,
    changePercent: -0.0206,
    label: 'Oct 20, 20',
    changeOverTime: 1.99701
  },
  {
    date: '2023-10-21',
    open: 102.47,
    close: 99.64,
    high: 103.79,
    low: 101.08,
    volume: 1703418,
    uOpen: 100.29,
    uClose: 100.99,
    uHigh: 106.42,
    uLow: 100.41,
    uVolume: 1693219,
    change: 0.22,
    changePercent: 0.2344,
    label: 'Oct 21, 20',
    changeOverTime: 2.001882
  },
  {
    date: '2023-10-22',
    open: 102.48,
    close: 98.19,
    high: 102.32,
    low: 96.94,
    volume: 2291145,
    uOpen: 102.98,
    uClose: 98.67,
    uHigh: 101.46,
    uLow: 98.21,
    uVolume: 2328063,
    change: -1.9,
    changePercent: -1.9523,
    label: 'Oct 22, 20',
    changeOverTime: 1.923123
  },
  {
    date: '2023-10-23',
    open: 99.75,
    close: 97.93,
    high: 102.07,
    low: 97,
    volume: 1593163,
    uOpen: 101.16,
    uClose: 97.73,
    uHigh: 99.1,
    uLow: 96,
    uVolume: 1619528,
    change: -0.64,
    changePercent: -0.6674,
    label: 'Oct 23, 20',
    changeOverTime: 1.8512
  },
  {
    date: '2023-10-26',
    open: 97,
    close: 93.6,
    high: 99.47,
    low: 93.27,
    volume: 2328125,
    uOpen: 99,
    uClose: 94.52,
    uHigh: 100.26,
    uLow: 94.16,
    uVolume: 2325606,
    change: -4.28,
    changePercent: -4.3517,
    label: 'Oct 26, 20',
    changeOverTime: 1.779216
  },
  {
    date: '2023-10-27',
    open: 95.66,
    close: 95.63,
    high: 96.42,
    low: 95.25,
    volume: 1881902,
    uOpen: 96.89,
    uClose: 96.6,
    uHigh: 97.72,
    uLow: 91.61,
    uVolume: 1870465,
    change: 0.48,
    changePercent: 0.5286,
    label: 'Oct 27, 20',
    changeOverTime: 1.749557
  },
  {
    date: '2023-10-28',
    open: 91.24,
    close: 92.18,
    high: 92.79,
    low: 89.74,
    volume: 1734513,
    uOpen: 92.87,
    uClose: 91.37,
    uHigh: 94.05,
    uLow: 92.24,
    uVolume: 1763200,
    change: -2.94,
    changePercent: -3.153,
    label: 'Oct 28, 20',
    changeOverTime: 1.669745
  }
]
